.font-46[data-v-b4f0aece] {
  font-size: 46px !important;
}
.font-22[data-v-b4f0aece] {
  font-size: 22px !important;
}
.op-stepper[data-v-b4f0aece] {
  max-width: 500px;
  margin: 0 auto;
}
.op-step[data-v-b4f0aece] {
  position: relative;
  width: 100%;
}
.op-step label[data-v-b4f0aece] {
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
}
.op-stepper .op-step.active span[data-v-b4f0aece] {
  background-color: #ff4500;
  color: #fff;
}
.op-stepper .op-step span[data-v-b4f0aece] {
  height: 40px;
  min-width: 40px;
  border: 3px solid #ff4500;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  font-size: 17px;
  background-color: #fff;
  font-weight: bold;
  margin-top: 5px;
  z-index: 9;
  position: relative;
}
.op-stepper .op-step[data-v-b4f0aece]:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #ddd;
  top: 45px;
  left: -50%;
  z-index: 0;
}
.op-stepper .op-step.active[data-v-b4f0aece]::before {
  background-color: #ff4500;
  height: 3px;
}
.op-stepper .op-step[data-v-b4f0aece]:first-child:before {
  display: none;
}
.gray-heading[data-v-b4f0aece] {
  font-size: 25px !important;
  font-weight: bold;
  color: #7e7e7e;
}
.plans_card[data-v-b4f0aece] {
  height: 100%;
}
.plans_card .card-header[data-v-b4f0aece] {
  background-color: #e5eaf1;
}
.plans_card .card-body[data-v-b4f0aece] {
  background-color: #f6f7f9;
}
.plans_card .card-body p[data-v-b4f0aece] {
  font-size: 18px;
}
small.power-text[data-v-b4f0aece] {
  font-size: 18px;
}
.btn.select_pln[data-v-b4f0aece] {
  border: 1px solid #ff4500;
  outline: none;
  border-radius: 50vh;
  padding: 12px 55px;
  display: inline-block;
  font-size: 20px;
  color: #000;
  font-weight: bold;
  background-color: #fff;
}
.step.new_plans_box[data-v-b4f0aece] {
  max-width: 1300px;
  width: 100%;
}
.new_plans_box .plans_card[data-v-b4f0aece] {
  border: 4px solid #ddd;
  cursor: pointer;
}
.new_plans_box .card-header[data-v-b4f0aece] {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.new_plans_box .card-body[data-v-b4f0aece] {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.new_plans_box .plans_card .selected-check[data-v-b4f0aece] {
  position: absolute;
  top: -20px;
  left: -20px;
  height: 45px;
  width: 45px;
  background: rgb(101, 173, 2);
  background: linear-gradient(180deg, rgba(101, 173, 2, 1) 0%, rgba(164, 209, 102, 1) 100%);
  text-align: center;
  color: #fff;
  font-size: 25px;
  line-height: 45px;
  border-radius: 15px;
  scale: 0;
  transition: 0.5s;
}
.new_plans_box .plans_card.active .selected-check[data-v-b4f0aece] {
  scale: 1;
}
.font-18[data-v-b4f0aece]{
  font-size: 18px !important;
}
.font-30[data-v-b4f0aece]{
  font-size: 30px !important;
}
.new_plans_box .plans_card.active[data-v-b4f0aece] {
  border: 4px solid #30b786;
}

/* ORDER PLAN START */
.input-label[data-v-b4f0aece] {
  font-size: 16px !important;
  font-weight: 800;
  text-transform: uppercase;
  text-align: left !important;
}
.select-img .img-box img[data-v-b4f0aece] {
  margin: 0 !important;
}
.select-img .img-box[data-v-b4f0aece] {
  height: 67px;
  background-color: #f6f6f6;
  width: 81px;
  border: 2px solid #bfbfbf;
  text-align: center;
  line-height: 62px;
}
.select-img .sp-heading-new[data-v-b4f0aece] {
  border-left: 0;
  border-radius: 0;
  box-shadow: none;
}
.step.choose-plan[data-v-b4f0aece] {
  min-width: 1300px;
}
.selecotr-item[data-v-b4f0aece] {
  position: relative;
  height: 100%;
}
.selector-item_radio[data-v-b4f0aece] {
  -webkit-appearance: none;
          appearance: none;
  display: none;
}
.selector-item_label[data-v-b4f0aece] {
  position: relative;
  width: 100%;
  text-align: center;
  border-radius: 20px;
  font-weight: 600;
  transition-duration: .5s;
  transition-property: transform, color, box-shadow;
  transform: none;
  cursor: pointer;
  display: block;
  border: 4px solid #d3d3d3;
  box-sizing: border-box;
}
.selector-item_label .selected-check[data-v-b4f0aece] {
  position: absolute;
  top: -16px;
  right: -15px;
  height: 46px;
  background: rgb(255 69 0);
  background: linear-gradient(180deg, rgb(255 69 0) 0%, rgb(254 179 41) 100%);
  width: 45px;
  line-height: 45px;
  color: #fff;
  font-size: 28px;
  border-radius: 15px;
  transform: scale(0);
  transition: 0.5s;
  z-index: 1;
}
.filter-btn .item-btn[data-v-b4f0aece] {
  font-size: 20px;
  font-weight: bold;
  color: #747474;
  text-transform: uppercase;
  background-color: #fff;
  padding: 17px 30px;
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;
  cursor: pointer;
}
.filter-btn .item-btn.active[data-v-b4f0aece],
.filter-btn .item-btn[data-v-b4f0aece]:hover {
  background-color: #1a75bb;
  color: #fff;
}
.filter-btn[data-v-b4f0aece] {
  border: 2px solid #ddd;
  border-radius: 15px;
  overflow: hidden;
}
.box-content[data-v-b4f0aece] {
  background-color: #f0f6ff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.selector-item_radio:checked+.selector-item_label[data-v-b4f0aece],
.selector-item_label.checked[data-v-b4f0aece] {
  background-color: var(--blue);
  color: var(--white);
  transform: translateY(-2px);
  border: 4px solid #ff4500;
}
.selector-item_radio:checked+.selector-item_label .selected-check[data-v-b4f0aece],
.selector-item_label.checked .selected-check[data-v-b4f0aece] {
  transform: scale(1);
  transition: 0.5s;
}
.choose-plan .sm-heading[data-v-b4f0aece] {
  font-size: 17px !important;
}
.selecotr-item h2[data-v-b4f0aece] {
  background-color: #c4dcfd;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.row-col strong[data-v-b4f0aece] {
  font-size: 17px;
}
.row-col[data-v-b4f0aece] {
  border-bottom: 2px solid #c4dcfd;
}
.row-col span[data-v-b4f0aece],
.row-col strong[data-v-b4f0aece] {
  line-height: 3;
}
.box-description strong[data-v-b4f0aece] {
  display: block;
  width: 100%;
  text-align: left;
  line-height: 3;
  font-size: 17px;
}
.plan-row[data-v-b4f0aece] {
  max-height: 610px;
  overflow: auto;
  padding-top: 20px;
}
.box-description .white-bg-text[data-v-b4f0aece] {
  background-color: #fff;
  border-radius: 10px;
  line-height: 1.6;
  text-align: left;
  font-weight: 400;
  padding: 10px;
  font-size: 18px;
  height: 78px;
  overflow: auto;
}
.price-div .plan-price[data-v-b4f0aece] {
  font-size: 30px;
  color: #1b75bc;
  font-weight: 600;
  display: block;
  margin-right: 8px;
}
.price-div .plan-duration[data-v-b4f0aece] {
  color: #1b75bc;
  line-height: 1.8;
  font-weight: 600;
  font-size: 19px;
}
.row-col span[data-v-b4f0aece] {
  font-weight: 400;
  font-size: 20px;
}
.roller-month .counter[data-v-b4f0aece] {
  font-size: 50px;
  color: #fff;
  background: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  /* font-family: 'circular'; */
  border: 4px solid #ddd;
  height: 70px;
  position: relative;
  overflow: hidden;
  border-left: 0;
  border-right: 0;
}
.cstm_width[data-v-b4f0aece] {
  width: 296px;
  margin: 20px 0px;
}
.new_plan_cards .plans_card .card-header[data-v-b4f0aece]{
  background-color: transparent;
  border-bottom: 0px;
  justify-content: space-between;
  height: 44%;
}
.new_plan_cards .category-icon-wrapper[data-v-b4f0aece]{
  background-color: #ff4500;
  padding: 20px;
  width: 130px;
  height: 130px;
  border-radius: 100px;
  object-fit: contain !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.new_plan_cards .category-icon-wrapper .category-icon[data-v-b4f0aece]{
  width: 70px;
  height: 70px;
  margin: 0px !important;
  object-fit: contain;
}
.new_plan_cards .bg_grey[data-v-b4f0aece]{

  /* width: 90%; */
  background-color: #eaeaea;
  border-radius: 5px;
  padding: 6px 14px;
  border: none;
}
.new_plan_cards .bg_grey strong[data-v-b4f0aece],
.new_plan_cards span[data-v-b4f0aece]{
  line-height: inherit
}
.new_plan_cards .per_month_text[data-v-b4f0aece]{
  font-size: 14px;
}
.new_plan_cards .selector-item_label[data-v-b4f0aece]{
  border-color: #1a75bb;
}
.new_plan_cards .selector-item_label.active[data-v-b4f0aece]{
  border-color: #ff4500;
}
.new_plan_cards  .price-div .plan-price[data-v-b4f0aece],
.new_plan_cards  .price-div .plan-duration[data-v-b4f0aece]{
  color: #ff4500;
}
.new_plan_cards .card-header[data-v-b4f0aece]{
  padding: 0px;
}
.new_plan_cards .category_title[data-v-b4f0aece]{
  background-color: #1a75bb;
  width: 106%;
  color: white;
  padding: 5px 0px;
  position: relative;
  border-radius: 0px;
}
.new_plan_cards.active .category_title[data-v-b4f0aece]{
  background-color: #ff4500;
}
.new_plan_cards .category_title[data-v-b4f0aece]::before{
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 10px 1px 0px;
  border-color: #eb2d2d transparent transparent transparent;
  transform: rotate(174deg);
  line-height: 0px;
  _border-color: #eb2d2d #000000 #000000 #000000;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
  position: absolute;
  left: -1px;
  top: -14px;
  z-index: 0;
}
.new_plan_cards .category_title[data-v-b4f0aece]::after{
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 15px 1px 0px;
  border-color: #eb2d2d transparent transparent transparent;
  transform: rotate(-88deg);
  line-height: 0px;
  _border-color: #eb2d2d #000000 #000000 #000000;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
  position: absolute;
  right: -3px;
  top: -12px;
}
.new_plan_cards  .plan_text[data-v-b4f0aece]{
    font-size: 12px;
    padding: 10px 20px;
    color: #3b3b3b;
    height: auto;
}
.cat_options .choice_text[data-v-b4f0aece]{
  position: absolute;
  right: -2px;
  top: 40px;
  color: #fff;
  z-index: 1;
  transform: rotate(45deg);
}
.cat_options .react-multi-carousel-item:nth-child(2) .option-box[data-v-b4f0aece]::after {
  content: "";
  top: 0;
  right: 0;
  width: 100px;
  position: absolute;
  height: 100px;
  border-bottom: solid 65px transparent;
  border-left: solid 65px transparent;
  border-right: solid 65px #1a75bb;
  border-top: solid 65px #1a75bb;
  border-top-right-radius: 16px;
}
.new_plan_cards .card-body[data-v-b4f0aece]{
  background-color: transparent;
}
.new_plan_cards .box-description .white-bg-text[data-v-b4f0aece]{
  overflow: hidden;
}
.new_plan_cards .react-multiple-carousel__arrow[data-v-b4f0aece]{
  border: 1px solid #fff!important;
}
/* new buttons style css  */
.tri-state-toggle[data-v-b4f0aece] {
  background: #e5e5e5;
  box-shadow: inset 0 2px 8px 0 rgba(165, 170, 174, 0.25);
  border-radius: 24px;
  display: inline-block;
  overflow: hidden;
  display: inline-flex;
  flex-direction: row;
  transition: all 500ms ease;
  border: 2px solid #cfcfcf;
}
.tri-state-toggle-button[data-v-b4f0aece] {
  border-radius: 22px;
  /* height: 44px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: transparent;
  border: 0px solid transparent;
  margin: 2px;
  color: #737373;
  cursor: pointer;
  margin: 0px;
  transition: all 0.5s ease;
  font-weight: 600; 


/*    -webkit-transition: all 0.5s ease-in-out;
-moz-transition:    all 0.5s ease-in-out;
-ms-transition:     all 0.5s ease-in-out;
-o-transition:      all 0.5s ease-in-out; */
transition:         all 0.5s ease;
}
.tri-state-toggle-button.active[data-v-b4f0aece] {
  background-color: #1a75bb;
  color: white;
  /* border: 1px solid rgba(207,207,207,0.6);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  font-weight: 500; */
  font-weight: 700; 
transition: all .5s ease-in;
}
.tri-state-toggle-button[data-v-b4f0aece]:focus {
outline: none;
}
.roller-month .counter .number[data-v-b4f0aece] {
  position: relative;
  top: -99%;
  height: 100%;
  width: 100%;
  color: #6f6f6f;
  font-size: 28px;
  line-height: 2;
}
.roller-month .counter .number span[data-v-b4f0aece] {
  font-size: 25px;
}
/* .roller-month .counter .number:before,.roller-month .counter .number:after{
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, transparent, #000000a3);
  position: absolute;
  z-index: 2;
  left: 0;
  top: -18px;
}
.counter .number::after {
  bottom: 0;
  background: linear-gradient(to top, transparent, #000000a3);
} */
.up[data-v-b4f0aece] {
  animation: up-data-v-b4f0aece linear 0.5s;
}
@keyframes up-data-v-b4f0aece {
from {
    transform: translateY(0);
}
to {
    transform: translateY(-100%);
}
}
.down[data-v-b4f0aece] {
  animation: down-data-v-b4f0aece linear 0.5s;
}
@keyframes down-data-v-b4f0aece {
from {
    transform: translateY(0);
}
to {
    transform: translateY(100%);
}
}
.roller-month .counter.shine[data-v-b4f0aece]:after{
  content:'';
  left: 0;
  top:0;
	transform:translateY(100%);
	width:100%;
	height:200px;
	position: absolute;
	z-index:1;
	animation: slide-data-v-b4f0aece 1s; /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 );
}
@keyframes slide-data-v-b4f0aece {
0% {transform:translateY(-100%);}
100% {transform:translateY(100%);}
}
.in-de-box button[data-v-b4f0aece]:disabled {
  background-color: #c3c3c3;
  cursor: no-drop;
}
.in-de-box button[data-v-b4f0aece] {
  width: 65px;
  font-size: 32px;
  border: 4px solid #ddd;
  color: grey;
  background-color: #f6f6f6;
}
.roller-month label[data-v-b4f0aece] {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 900;
  margin-right: 20px;
}
.plan-row .selecotr-item:nth-child(3n+2) h2[data-v-b4f0aece] {
  background-color: #96f6f7;
}
.plan-row .selecotr-item:nth-child(3n+2) .box-content[data-v-b4f0aece] {
  background-color: #f0ffff;
}
.plan-row .selecotr-item:nth-child(3n+2) .row-col[data-v-b4f0aece] {
  border-bottom: 2px solid #96f6f7;
}
.plan-row .selecotr-item:nth-child(3n+3) h2[data-v-b4f0aece] {
  background-color: #7cf3c8;
}
.plan-row .selecotr-item:nth-child(3n+3) .box-content[data-v-b4f0aece] {
  background-color: #e9fff7;
}
.plan-row .selecotr-item:nth-child(3n+3) .row-col[data-v-b4f0aece] {
  border-bottom: 2px solid #7cf3c8;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
*[data-v-b4f0aece] {
  scrollbar-width: thin;
  scrollbar-color: #ff4500 #ff4500;
}

/* Chrome, Edge, and Safari */
*[data-v-b4f0aece]::-webkit-scrollbar {
  width: 6px;
}
*[data-v-b4f0aece]::-webkit-scrollbar-track {
  background: #fff;
}
*[data-v-b4f0aece]::-webkit-scrollbar-thumb {
  background-color: #ff4500;
  border-radius: 9px;
  border: 0px inset #ffffff;
}
@media only screen and (max-width: 1023.5px) {
.new_plan_cards .category_title[data-v-b4f0aece]::before{
    left: -1px;
    top: -13px;
    border-width: 15px 19px 0px 0px;
}
.new_plan_cards .category_title[data-v-b4f0aece]::after{
    right: -3px;
    top: -16px;
    border-width: 15px 16px 1px 0px;
}
}
@media only screen and (max-width: 768px) {
.checkmark-wrapper[data-v-b4f0aece] {
    display: none;
}
.step.choose-plan[data-v-b4f0aece] {
    min-width: 100%;
}
.in-de-box button[data-v-b4f0aece]{
    width: auto!important;
}
.roller-month .counter .number[data-v-b4f0aece]{
    font-size: 14px;
}
.roller-month .counter .number span[data-v-b4f0aece]{
    font-size: 14px;
    margin-top: -10px;
}
.choose_btw_payment .only_text[data-v-b4f0aece]{
    font-size: 12px;
    margin-left: 10px;
}
.choose_btw_payment .left[data-v-b4f0aece]{
    display: block;
}
}
.step.new_plans_box[data-v-b4f0aece] {
  zoom: 0.8;
  /* margin: 100px 0; */
}
.order_body .step-17 .step.new_plans_box[data-v-b4f0aece]{
  zoom: 0.7;
}
.half-screen-loader[data-v-b4f0aece] {
  height: 50vh;
}
.select_number_packages[data-v-b4f0aece]{
  width: 1160px !important;
}

/* ORDER PLAN END */
