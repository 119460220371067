/* Do Css here */
input.card_radio_btn[data-v-ff868dd8] {
  -webkit-appearance: none;
          appearance: none;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: #f6f6f4;
  border: 2px solid #999;
  transition: 0.2s all linear;
  margin-right: 5px;
  position: relative;
  top: 4px;
  margin: 10px 10px 10px 0px;
}
input.card_radio_btn[data-v-ff868dd8]:checked {
  border: 4px solid #f6f6f4;
  outline-color: #c7c7c7;
  outline-style: solid;
  outline-width: 2px;
  background-color: #5799cb;
}
.card_number_digits span[data-v-ff868dd8]{
  font-weight: 700;
}
.card_number_digits[data-v-ff868dd8] {
  font-size: 20px;
  font-family: inherit;
  font-weight: 500;
  margin: 0;
}
div>.cc_img[data-v-ff868dd8] {
  margin-right: 10px;
  border: 2px solid #9da5b1;
  border-radius: 4px;
}
.col-6.cursor-pointer>label[data-v-ff868dd8] {
  cursor: pointer !important;
}
.radio_btn_details[data-v-ff868dd8] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.choose_btw_payment[data-v-ff868dd8]{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999999;
  border-radius: 50px;
  position: relative;
}
.choose_btw_payment .left[data-v-ff868dd8] {
  width: 50%;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  cursor: pointer;
}
.choose_btw_payment .right[data-v-ff868dd8] {
  width: 50%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
.choose_btw_payment .only_text[data-v-ff868dd8] {
  font-size: 25px;
  color: #fff;
  font-family: inherit;
  font-weight: bold;
  /* padding-left: 10px; */
}
.choose_btw_payment .or_separator[data-v-ff868dd8] {
  position: absolute;
  left: calc(50% - 2px);
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #fff;
}
.or_separator[data-v-ff868dd8]::after {
  content: "OR";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9651a;
  border: 4px solid #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
}
.choose_btw_payment .button.active[data-v-ff868dd8]{background-color: #e9651a;}
.terms_checkbox[data-v-ff868dd8] {
  position: relative;
}
.terms_checkbox .terms_inside_checkbox[data-v-ff868dd8]{
  transform: scale(2.5) ;
  top: 6px;
  left: 14px;
  z-index: 999999;
}
@media only screen and (max-width: 767px) and (min-width: 280px) {
.choose_btw_payment .left[data-v-ff868dd8]{
    display: block!important;
}
.choose_btw_payment .only_text[data-v-ff868dd8]{
    font-size: 15px!important;
    padding-left: 8px!important;
}
}
