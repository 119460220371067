@font-face {
  font-family: "Antro Vectra";
  src        : url("../fonts/Antro_Vectra.otf");
}

@font-face {
  font-family: "Arty Signature";
  src        : url("../fonts/Arty Signature.otf");
}

@font-face {
  font-family: "Creattion";
  src        : url("../fonts/Creattion Demo.otf");
}

@font-face {
  font-family: "Southam";
  src        : url("../fonts/Southam\ Demo.otf");
}

body {
  /* font-family       : 'Open Sans', sans-serif;
  background-color  : #fff;
  margin            : 0; */
  padding              : 0;
  /* max-width         : 100vw; */
  /* overflow          : hidden; */
  /* background        : url("../img/wizard-bg.png") no-repeat !important; */
  /* background-size   : cover !important; */
  /* min-height        : 100vh; */
  overflow-x: hidden;
}

.plan-modal .btn-primary {
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  border-radius: 30px;
  background-color: rgb(81, 112, 156);
  box-shadow: 0px 15px 35px 0px rgb(81 112 156 / 32%);
  box-sizing: border-box;
  /* margin-right: 30px; */
  cursor: pointer;
  transition: all 0.3s;
}
.plan-modal .btn-primary:hover {
  background-color: rgb(255, 69, 0);
}
a.carousel-control-next, a.carousel-control-prev {
  height: 40px;
  background: rgb(255 69 0);
  background: linear-gradient(180deg, rgb(255 69 0) 0%, rgb(254 179 41) 100%);
  width: 41px;
  line-height: 45px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  border-radius: 10px;
  right: -5px;
}
a.carousel-control-prev{
  left: -5px;
  right: auto;
}
a.carousel-control-next .carousel-control-next-icon,a.carousel-control-prev .carousel-control-prev-icon {
  background-size: 22px;
  background-position: center;
  filter: none;
}
.zindex {
  z-index: 9999999;
}
.plan-modal .modal-body h3 {
  font-size: 30px;
  font-weight: 400;
  color: #1a75bb;
  line-height: 1.2;
  letter-spacing: -.5px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.plan-modal .modal-footer {
  border-top: none;
  padding-top: 0;
  justify-content: center;
}
.plan-modal .modal-body {
  padding-bottom: 0;
}
.footer_upper_bar,
.footer_bottom_bar {
  display: none;
}

::selection {
  background : #b3d4fc;
  text-shadow: none;
}

fieldset {
  border : 0;
  margin : 0;
  padding: 0;
}

/*Start of Navbar*/
.steps-nav {
  width           : 100%;
  height          : 95px;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  background-color: #fff;
  margin          : 0;
  padding         : 0;
}

.steps-nav .logo {
  display    : inline-block;
  margin-left: 8%;
  line-height: 1;
}

.steps-nav .logo img {
  vertical-align: middle;
}

.steps-nav .contact-btn {
  display        : flex;
  align-items    : center;
  padding        : 10px 20px;
  font-size      : 18px;
  font-weight    : 700;
  color          : #1a75bb;
  text-decoration: none;
  text-transform : uppercase;
  border         : 2px solid #1a75bb;
  border-radius  : 30px;
  margin-right   : 8%;
}

.steps-nav .contact-btn img {
  margin-right: 10px;
}

/*End of Navbar*/
/*Start of Main Section*/
.main {
  /* background         : url(../img/wizard-bg.png) no-repeat; */
  background         : transparent;
  background-position: left;
  background-size    : cover;
  margin             : 0 !important;
  position           : relative;
  transition         : .3s all;
}

/* .main.slideRight {
  margin-left: 300px !important;
} */
.add_new_number_div {
  max-height: 350px;
  overflow: scroll;
  overflow-x: visible;
}
div#example-advanced-form input[type=number]::placeholder,
div#example-advanced-form input[type=text]::placeholder {
  color: rgb(175, 172, 172);
}
button.provide_later_button {
  margin: 10px auto;
}

.main .step {
  position        : relative;
  width           : 950px;
  max-width       : 100%;
  background-color: #fffc;
  padding         : 50px 70px 50px 70px;
  border-radius   : 30px;
  box-shadow      : 0px 23px 45px 0px rgba(18, 42, 78, 0.1);
  box-sizing      : border-box;
  overflow        : visible;
}

.order_body .main .step {
  box-shadow: 20px 19px 45px 20px rgb(0 0 0 / 29%);
}
.carousel-inner {
  padding-top: 8px;
}
.main .step .sm-heading , .more-plans-option .sm-heading{
  font-size     : 14px;
  font-weight   : 700;
  color         : #222;
  line-height   : 1;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  margin-top    : 0;
  margin-bottom : 15px;
}
.more-plans-option .sm-heading {
  color: #1d77b9;
}
.public-sidebar.sticky {
  position: fixed !important;
  top: 0;
  height: 100vh;
}
.invalid:before {
  position: relative;
  left    : -5px;
  content : "";
}

.valid:before {
  position: relative;
  left    : -5px;
  content : "";
}

.main .step .lg-heading {
  font-size     : 30px;
  font-weight   : 400;
  color         : #1a75bb;
  line-height   : 1.2;
  letter-spacing: -0.5px;
  margin-bottom : 30px;
  margin-top    : 0px;
}
.main .step .sp-heading-new {
  font-size     : 20px;
  font-weight   : 600;
  /* appearance    : auto;
  -moz-appearance: auto; */
  /* -webkit-appearance: auto; */
  color         : #4b90c7;
  line-height   : 1.2;
  letter-spacing: -0.5px;
  margin-bottom : 30px;
  margin-top    : 0px;
  padding: 20px 10px;
  text-transform: uppercase;
}
.choose-plan .email-box {
  padding: 13px 15px !important;
  font-size: 22px !important;
}
.row1-col2 .carrier-option {
  padding: 9px 10px;
  font-size: 20px;
}
.more-plans-option h5.label {
  font-size: 14px !important;
  margin-bottom: 7px;
  color: #000;
  font-weight: 600;
}
.more-plans-option h5.label span {
  color: red;
}
.more-plans-option thead {
  background-color: #1a75bb;
  color: #fff;
  text-align: center;
}
/* MULTI CAROUSEL */
.react-multi-carousel-item {
  padding: 0 15px !important;
}
.react-multiple-carousel__arrow--left {
  left: 0 !important;
  background-color: #1a75bb !important;
}
.react-multiple-carousel__arrow--right {
  right: 0 !important;
  background-color: #1a75bb !important;
  border: 1px solid #fff!important;
}
.react-multiple-carousel__arrow{
  border: 1px solid #fff!important;

}

.react-multi-carousel-list {
  padding: 20px 0;
}
.more-plans-option thead th {
  padding: 8px 0;
  border-color: #ddd;
}

.more-plans-option .react-tel-input input.form-control.default {
  height: 42px !important;
  font-size: 20px !important;
}
.main .step .footer {
  background     : transparent;
  border         : none;
  display        : flex;
  align-items    : center;
  margin-top     : 40px;
  justify-content: space-between;
  padding        : 0px;
  margin-bottom  : -30px;
}
/* My Custom RAdio Button */

/* my custom radio button end */
.main .step .footer .proceed-btn {
  min-width       : 160px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  padding         : 10px 20px;
  font-size       : 18px;
  font-weight     : 700;
  color           : #fff;
  text-align      : center;
  text-decoration : none;
  text-transform  : uppercase;
  border          : 0;
  border-radius   : 30px;
  background-color: rgb(255, 69, 0);
  box-shadow      : 0px 15px 35px 0px rgb(255 66 0 / 32%);
  box-sizing      : border-box;
  /* margin-right : 30px; */
  cursor          : pointer;
  transition      : all 0.3s;
}

.main .step .footer .proceed-btn:hover {
  background-color: #51709c;
  transition      : all 0.3s;
}

.main .step .add-number-btn {
  min-width          : 160px;
  display            : flex;
  align-items        : center;
  justify-content    : center;
  padding            : 10px 20px;
  font-size          : 18px;
  font-weight        : 700;
  color              : #fff;
  text-align         : center;
  text-decoration    : none;
  text-transform     : uppercase;
  border             : 0;
  border-radius      : 30px;
  background-color   : #4bae4f;
  /* box-shadow      : 0px 15px 35px 0px rgb(255 66 0 / 32%); */
  box-sizing         : border-box;
  /* margin-right    : 30px; */
  cursor             : pointer;
  transition         : all 0.3s;
  position           : absolute;
  right              : 70px;
  bottom             : 50px;
}

.main .step .add-carrier-request {
  min-width          : 160px;
  display            : flex;
  align-items        : center;
  justify-content    : center;
  padding            : 10px 20px;
  font-size          : 18px;
  font-weight        : 700;
  color              : #fff;
  text-align         : center;
  text-decoration    : none;
  text-transform     : uppercase;
  border             : 0;
  border-radius      : 30px;
  background-color   : #4bae4f;
  /* box-shadow      : 0px 15px 35px 0px rgb(255 66 0 / 32%); */
  box-sizing         : border-box;
  /* margin-right    : 30px; */
  cursor             : pointer;
  transition         : all 0.3s;
  margin             : 10px auto;
}

.main .step .add-carrier-request:hover {
  background-color: #2d7c30;
}

.main .step .add-number-btn:hover {
  background-color: #2d7c30;
}

.generic-help-link {
  position: absolute;
  right: 70px;
  bottom: 25px;
  cursor: pointer;
  max-width: calc(100% - 350px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* .generic-help-link {} */
.generic-help-link img {
  margin-right: 8px;
  max-height  : 30px;
  max-width   : 30px;
}
.dashboard-counter {
  font-size: 30px;
  text-align: center;
  width: 100%;
  color: green;
}
.proceed-btn.disabled {
  background-color: #333 !important;
  cursor          : default;
  box-shadow      : 0px 15px 35px 0px rgb(0 0 0 / 32%) !important;
}

/* .main .step .footer .pe {
  font-size     : 14px;
  font-weight   : 600;
  color         : #222;
  line-height   : 1;
  text-transform: uppercase;
  user-select   : none;
  position      : absolute;
  bottom        : 30px;
  margin        : 0px 25px;
}
.main .step .footer .pe img {
  margin-left: 5px;
} */
.main .step .prev-step {
  border-top-left-radius: 18px;
}

.main .step .prev-step,
.main .step .next-step {
  position        : absolute;
  right           : 0;
  bottom          : 42px;
  width           : 40px;
  height          : 40px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  background-color: #51709c;
  text-decoration : none;
  cursor          : pointer;
  border          : none;
}

.next-step.disabled,
.prev-step.disabled {
  background-color: #80858c !important;
  cursor          : default !important;
}

.main .step .next-step {
  bottom                    : 0;
  border-bottom-right-radius: 25px;
}

/*Start of First Step*/
.main .step.first .mt-25 {
  margin-top: 25px;
}

.main .step.first .field {
  display     : inline-block;
  margin-right: 20px;
}

.main .step.first .field:last-child {
  margin-right: 0;
}

.main .step.first .field input {
  min-width       : 265px;
  height          : 40px;
  font-size       : 24px;
  font-weight     : 500;
  color           : #222;
  background-color: transparent;
  line-height     : 1;
  text-transform  : capitalize;
  margin          : 0;
  padding         : 0;
  border          : 0;
  border-radius   : 0;
  border-bottom   : 2px solid #e0e0da;
  outline         : 0;
  box-shadow      : none;
}

.main .step.first .field input::placeholder {
  color: #ddd;
}

/*End of First Step*/
/*Start of Second Step*/
.main .step.third .mt-25 {
  margin-top: 25px;
}

.main .step.third .phone-number,
.main .step.fifth .phone-number,
.main .step.ninth .phone-number,
.main .step.second-step .phone-number {
  display: flex;
}

.main .step.third .phone-number input,
.main .step.ninth .phone-number input,
.main .step.fifth .phone-number input,
.main .step.second-step .phone-number input {
  width           : 100%;
  height          : 55px;
  padding         : 10px 15px;
  font-size       : 24px;
  font-weight     : 400;
  color           : #ddd;
  background-color: #fff;
  border          : 1px solid #dbdbd9;
  border-radius   : 0;
  outline         : 0;
  box-shadow      : none;
  box-sizing      : border-box;
}

.main .step.third .phone-number input::placeholder,
.main .step.ninth .phone-number input::placeholder {
  color: #ddd;
}

.main .step.third .sm-text,
.main .step.second-step .sm-text,
.main .step.fifth .sm-text {
  font-size  : 16px;
  font-weight: 400;
  color      : #222;
  line-height: 1;
  margin     : 25px 0;
}

.main .step.third .code,
.main .step.ninth .code,
.main .step.second-step .code,
.main .step.fifth .code {
  display    : flex;
  align-items: center;
}

.step .code {
  display    : flex;
  align-items: center;
}

.main .step.third .code .digits,
.main .step.ninth .code .digits,
.main .step.second-step .code .digits,
.main .step.fifth .code .digits {
  position      : relative;
  display       : flex;
  align-items   : center;
  flex-direction: row;
}

.step .code .digits {
  position      : relative;
  display       : flex;
  align-items   : center;
  flex-direction: row;
}

.main .step.third .code .digits input,
.main .step.ninth .code .digits input,
.main .step.second-step .code .digits input,
.main .step.fifth .code .digits input {
  border             : 1px solid #d3d3d3;
  border-right       : none;
  font-size          : 2rem;
  height             : 4rem;
  outline            : none;
  text-align         : center;
  transition-duration: .25s;
  transition-property: color, border, box-shadow, transform;
  width              : 4rem;
}

.step .code .digits input {
  border             : 1px solid #d3d3d3;
  border-right       : none;
  font-size          : 2rem;
  height             : 4rem;
  outline            : none;
  text-align         : center;
  transition-duration: .25s;
  transition-property: color, border, box-shadow, transform;
  width              : 4rem;
}

.main .code .digits input:last-of-type {
  border-radius: 0 0.5rem 0.5rem 0;
  border-right : 1px solid #d3d3d3;
}

.main .code .digits input:first-of-type {
  border-radius: 0.5rem 0 0 0.5rem;
}

.main .step.third .code .digits input:last-child {
  margin-right: 0;
}

.main .step.third .code .digits input::-webkit-outer-spin-button,
.main .step.third .code .digits input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin            : 0;
}

.main .step.third .code .digits input[type=number] {
  -moz-appearance: textfield;
}

.main .step.third .code .resend-code,
.main .step.ninth .code .resend-code,
.main .step.second-step .code .resend-code,
.main .step.fifth .code .resend-code {
  font-size      : 14px;
  font-weight    : 600;
  color          : #222;
  margin-left    : 20px;
  padding-bottom : 5px;
  border-bottom  : 3px solid #ff4500;
  text-decoration: none;
  cursor         : pointer;
}

.step .code .resend-code {
  font-size      : 14px;
  font-weight    : 600;
  color          : #222;
  margin-left    : 20px;
  padding-bottom : 5px;
  border-bottom  : 3px solid #ff4500;
  text-decoration: none;
  cursor         : pointer;
}

.resend-code.disabled {
  cursor: not-allowed;
}

.step .code .resend-code img {
  margin-right: 5px;
}

.step .code .resend-code img {
  margin-right: 5px;
}

.step .code .resend-code img {
  margin-right: 5px;
}

.step .code .resend-code img {
  margin-right: 5px;
}

.step.third .field:last-child {
  margin-right: 0;
}

.main .step.third .field input,
.main .step.second-step .field input,
.main .step.fifth .field input {
  min-width       : 265px;
  height          : 55px;
  font-size       : 24px;
  font-weight     : 500;
  color           : #222;
  background-color: #fff;
  line-height     : 1;
  text-transform  : capitalize;
  margin          : 0;
  padding         : 0 0 0 55px;
  border          : 0;
  border-radius   : 0;
  border          : 1px solid #e0e0da;
  outline         : 0;
  box-shadow      : none;
}

.main .step.third .field input::placeholder {
  color: #ddd;
}

.main .step.fifth .field input::placeholder {
  color: #ddd;
}

/*End of Second Step*/
/*Start of Third Step*/
.row-f {
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.main .step.second .signupbutton_content {
  width     : 100%;
  max-width : 100%;
  margin-top: 25px;
}

.main .step.second .signupbutton_content a {
  position        : relative;
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 32%;
  height          : 55px;
  font-size       : 24px;
  font-weight     : 400;
  color           : #8b8b8b;
  text-decoration : none;
  text-transform  : capitalize;
  background-color: #fff;
  border          : 1px solid #dbdbd9;
  border-radius   : 30px;
  margin-bottom   : 20px;
  transition      : all 0.3s;
}

.main .step.second .signupbutton_content a.with-apple {
  margin-bottom: 0;
}

.main .step.second .signupbutton_content a.with-apple .white {
  display: none;
}

.main .step.second .signupbutton_content a.with-fb:hover {
  background-color: #3a559f;
  color           : #fff;
  transition      : all 0.3s;
}

.main .step.second .signupbutton_content a.with-google:hover {
  background-color: #157de5;
  color           : #fff;
  transition      : all 0.3s;
}

.main .step.second .signupbutton_content a.with-apple:hover {
  background-color: #000;
  color           : #fff;
  transition      : all 0.3s;
}

.main .step.second .signupbutton_content a.with-apple:hover .white {
  display: block;
}

.main .step.second .signupbutton_content a.with-apple:hover .black {
  display: none;
}

.main .step.second .signupbutton_content a .icon {
  margin-right: 15px;
  /*position  : absolute;
  left        : 25px;
  top         : 50%;
  transform   : translateY(-50%); */
}

.main .step.second .signupbutton_content .email-signup {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

.main .step.second .signupbutton_content .email-signup .text {
  font-size  : 16px;
  font-weight: 400;
  color      : #222;
  margin     : 25px 30px 25px 0;
  white-space: nowrap;
}

.step .signupbutton_content .email-signup .line {
  width           : 100%;
  height          : 1px;
  background-color: #dbdbd9;
}

.step .signupbutton_content .email {
  position: relative;
}

.step .signupbutton_content .email .icon {
  position: absolute;
  left    : 20px;
  bottom  : 15px;
  width   : 32px;
  height  : 25px;
}

.step .signupbutton_content .email .icon.user.country {
  z-index: 1;
}

.step .signupbutton_content .email .icon.user.state {
  bottom: 10px;
}

.step .signupbutton_content .email .icon.user {
  left  : 10px;
  width : 25px;
  height: 25px;
}

.step .signupbutton_content .email input {
  width           : 100%;
  height          : 56px;
  font-size       : 24px;
  font-weight     : 400;
  color           : #3d3d3d;
  background-color: #fff;
  padding-left    : 40px;
  border          : 1px solid #dbdbd9;
  border-radius   : 0;
  box-sizing      : border-box;
  outline         : 0;
  box-shadow      : none;
}

.step .signupbutton_content .email .existing-error.show-existing-error {
  display: inline-block !important;
}

.step .signupbutton_content .email .existing-error {
  /* display      : none; */
  background   : #ffffff;
  color        : #643045;
  font-weight  : bold;
  padding      : 5px 10px;
  font-size    : 13px;
  line-height  : 20px;
  border-radius: 4px;
  position     : absolute;
  bottom       : -40px;
  opacity      : 0;
  transition   : opacity .3s;
  margin-bottom: 0.5em;
  cursor       : pointer;
}

.main .step.second .signupbutton_content .email:hover label.existing-error {
  opacity: 1 !important;
}

/*End of Third Step*/
/*Start of Forth Step*/
.main .step.forth .forth-content {
  margin-top: 25px;
}

.main .step.forth .forth-content .work,
.main .step.sixth .forth-content .work {
  width              : 90%;
  height             : 55px;
  font-size          : 24px;
  font-weight        : 400;
  color              : #8b8b8b !important;
  padding-left       : 20px;
  background-color   : #fff !important;
  border             : 1px solid #dbdbd9;
  border-radius      : 0;
  box-sizing         : border-box;
  outline            : 0;
  box-shadow         : none;
  margin-bottom      : 0;
  -webkit-appearance : none;
  -moz-appearance    : none;
  background         : url("../img/icon-dd-arrow.png") no-repeat;
  background-position: center right 20px;
}

/*End of Forth Step*/
/*Start of Fifth Step*/
.main .step.fifth .fifth-content {
  display    : flex;
  align-items: center;
  margin-top : 25px;
}

.fifth-content .radio {
  margin-right: 70px;
  padding-left: 0;
}

.fifth-content .radio input[type=radio] {
  position: absolute;
  opacity : 0;
}

.fifth-content .radio input[type=radio]+.radio-label {
  display       : flex;
  align-items   : center;
  font-size     : 24px;
  font-weight   : 400;
  color         : #8b8b8b !important;
  text-transform: uppercase;
}

.fifth-content .radio input[type=radio]+.radio-label:before {
  content       : "";
  background    : #fff;
  border-radius : 13px;
  border        : 1px solid #b4b4b4;
  display       : inline-block;
  width         : 35px;
  height        : 35px;
  margin-right  : 15px;
  vertical-align: top;
  cursor        : pointer;
  text-align    : center;
  transition    : all 250ms ease;
}

.fifth-content .radio input[type=radio]:checked+.radio-label:before {
  background-color: #52719c;
  box-shadow      : inset 0 0 0 6px #f4f4f4;
}

.fifth-content .radio input[type=radio]:focus+.radio-label:before {
  outline: none;
}

.fifth-content .radio input[type=radio]+.radio-label:empty:before {
  margin-right: 0;
}

/*End of Fifth Step*/
/*Start of Sixth Step*/
.main .step.sixth .sixth-content {
  margin-top: 25px;
}

.main .step.sixth .sixth-content .looking-for {
  margin-top: 25px;
}

.main .step.eight .sixth-content {
  margin-top: 25px;
}

.main .step.eight .sixth-content .looking-for {
  margin-top: 25px;
}

.main .step.sixth .sixth-content .looking-for .item,
.main .step.eight .sixth-content .looking-for .item {
  display      : flex;
  align-items  : center;
  margin-bottom: 30px;
}

.custom-cb {
  position: absolute;
  opacity : 0;
}

.custom-cb+label {
  position     : relative;
  font-size    : 21px;
  font-weight  : 400;
  color        : #505050;
  cursor       : pointer;
  padding-left : 55px;
  line-height  : 1.2;
  margin-bottom: 0 !important;
}

.custom-cb+label {
  position     : relative;
  font-size    : 21px;
  font-weight  : 400;
  color        : #505050;
  cursor       : pointer;
  padding-left : 55px;
  line-height  : 1.2;
  margin-bottom: 0 !important;
}

.custom-cb+label:before {
  content   : "";
  position  : absolute;
  top       : -8px;
  left      : 0;
  width     : 40px;
  height    : 40px;
  background: white;
  border    : 1px solid #b4b4b4;
}

.custom-cb:disabled+label {
  color : #ddd;
  cursor: auto;
}

.custom-cb:disabled+label:before {
  box-shadow: none;
  background: #ddd;
}

.custom-cb:checked+label:after {
  content   : "";
  position  : absolute;
  left      : 11px;
  top       : 12px;
  background: #52719c;
  width     : 7px;
  height    : 7px;
  box-shadow: -3px 0 0 #52719c, 4px 0 0 #52719c, 4px -2px 0 #52719c, 4px -14px 0 #52719c, 4px -20px 0 #52719c, 4px -8px 0 #52719c;
  transform : rotate(45deg);
}

.custom-cb:checked+label[for^="preference_"]:after {
  top: 15px;
}

.custom-cb:checked+label[for="preference_three"]:after {
  top: 22px;
}

/*End of Sixth Step*/
/*End of Main Section*/
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* SAAD's Additional CSS */
.step {
  /* opacity: 1 !important; */
  transition : .5s all !important;
  /* display : none; */
  /* position: absolute !important;
  top        : 50% !important;
  left       : 50% !important; */
}

div.step {
  /* width:100%; */
  margin-top: 0%;
  opacity   : 1;
  overflow  : hidden;
}

div.step {
  animation: slide-left 0.5s;
}

@keyframes slide-left {
  from {
    margin-top: 30%;
    opacity   : 0;
    /* width  : 300%;  */
  }

  to {
    opacity   : 1;
    margin-top: 0%;
    /* width  : 100%; */
  }
}

.step.body {
  /* opacity      : 0; */
  /* margin-top: -100% !important; */
  /* display: block !important; */
  transform: translateY(-150%) translateX(-50%);
  opacity  : 0;
  animation: sleepStep 0.3s;
}

.step.body.current {
  /* margin-top: 0% !important; */
  opacity  : 1;
  transform: translateY(calc(-50% + 47.5px)) translateX(-50%);
  overflow : visible;
  display  : block !important;
  animation: awakeStep 0.3s;
}

@keyframes sleepStep {
  0% {
    opacity  : 1;
    transform: translateY(calc(-50% + 47.5px)) translateX(-50%);
  }

  10% {
    opacity  : 0.9;
    transform: translateY(calc(-60% + 47.5px)) translateX(-50%);
  }

  20% {
    opacity  : 0.8;
    transform: translateY(calc(-70% + 47.5px)) translateX(-50%);
  }

  30% {
    opacity  : 0.7;
    transform: translateY(calc(-80% + 47.5px)) translateX(-50%);
  }

  40% {
    opacity  : 0.6;
    transform: translateY(calc(-90% + 47.5px)) translateX(-50%);
  }

  50% {
    opacity  : 0.5;
    transform: translateY(calc(-100% + 47.5px)) translateX(-50%);
  }

  60% {
    opacity  : 0.4;
    transform: translateY(calc(-110% + 47.5px)) translateX(-50%);
  }

  70% {
    opacity  : 0.3;
    transform: translateY(calc(-120% + 47.5px)) translateX(-50%);
  }

  80% {
    opacity  : 0.2;
    transform: translateY(calc(-130% + 47.5px)) translateX(-50%);
  }

  90% {
    opacity  : 0.1;
    transform: translateY(calc(-140% + 47.5px)) translateX(-50%);
  }

  100% {
    display  : none;
    opacity  : 0;
    transform: translateY(calc(-150% + 47.5px)) translateX(-50%);
  }
}

@keyframes awakeStep {
  0% {
    opacity  : 0.1;
    transform: translateY(calc(-0% + 47.5px)) translateX(-50%);
  }

  10% {
    opacity  : 0.1;
    transform: translateY(calc(-05% + 47.5px)) translateX(-50%);
  }

  20% {
    opacity  : 0.2;
    transform: translateY(calc(-10% + 47.5px)) translateX(-50%);
  }

  30% {
    opacity  : 0.3;
    transform: translateY(calc(-15% + 47.5px)) translateX(-50%);
  }

  40% {
    opacity  : 0.4;
    transform: translateY(calc(-20% + 47.5px)) translateX(-50%);
  }

  50% {
    opacity  : 0.5;
    transform: translateY(calc(-25% + 47.5px)) translateX(-50%);
  }

  60% {
    opacity  : 0.6;
    transform: translateY(calc(-30% + 47.5px)) translateX(-50%);
  }

  70% {
    opacity  : 0.7;
    transform: translateY(calc(-35% + 47.5px)) translateX(-50%);
  }

  80% {
    opacity  : 0.8;
    transform: translateY(calc(-40% + 47.5px)) translateX(-50%);
  }

  90% {
    opacity  : 0.9;
    transform: translateY(calc(-45% + 47.5px)) translateX(-50%);
  }

  100% {
    opacity  : 1;
    transform: translateY(calc(-50% + 47.5px)) translateX(-50%);
  }
}

.step.body.current~.step.body {
  /* margin-top: 100% !important; */
  /* opacity  : 0; */
  transform: translateY(100%) translateX(-50%);
  display  : none !important;
}

.content.clearfix {
  overflow: hidden !important;
  height  : 100vh;
}

.steps-nav {
  z-index : 99;
  position: fixed;
}

.wizard>.steps {
  position: fixed;
  z-index : 99;
  top     : 95px;
}

.checkmark-wrapper,
.crossmark-wrapper,
.circlemark-wrapper {
  /* height: 60px;
  width: 400px; */
  flex-direction    : row-reverse;
  align-items       : center;
  justify-content   : center;
  position          : absolute;
  top               : -18px;
  right             : 0px;
  /* display        : none; */
  z-index           : 999;
  width             : auto;
  height            : 56px;
}

.checkmark-wrapper .message,
.crossmark-wrapper .message,
.circlemark-wrapper .message {
  transition     : .3s all;
  width          : 100%;
  height         : 40px;
  opacity        : 1;
  border-radius  : 50px;
  border-radius  : 5;
  align-items    : center;
  justify-content: flex-start;
  display        : flex;
  color          : #fff;
  font-weight    : bold;
  font-size      : 13px;
  padding        : 0px;
  background     : #ff4500;
  border         : 1px solid rgb(255, 69, 0);
  flex-wrap      : wrap;
  word-wrap      : normal;
  overflow       : hidden;
  text-transform : uppercase;
  font-size      : 20px;
  padding-left   : 20px;
  padding-right  : 60px;
}

.checkmark-wrapper .message.dontslide,
.crossmark-wrapper .message.dontslide,
.circlemark-wrapper .message.dontslide {
  opacity: 0 !important;
  width  : 0px !important;
  padding: 0px !important;
}

.checkmark-wrapper .message {
  border-color: rgb(0, 128, 0);
  width       : 105%;
  float       : right;
  background  : rgb(0, 128, 0, 1);
}

.circlemark-wrapper .message {
  border-color: rgb(32, 121, 182);
  background  : rgb(32, 121, 182, 0.5);
}

/* .wizard>.content>.body label.error{
  display: none !important;
} */
div.field {
  position: relative;
}

.wizard>.content>.body .field label.error {
  display      : inline-block;
  background   : #ffffff;
  color        : #643045;
  font-weight  : bold;
  padding      : 5px 10px;
  font-size    : 13px;
  border-radius: 4px;
  position     : absolute;
  bottom       : -40px;
  opacity      : 0;
  transition   : opacity .3s
}

.wizard>.content>.body .coverage-field label.error {
  display      : inline-block;
  background   : #ffffff;
  color        : #643045;
  font-weight  : bold;
  padding      : 5px 10px;
  font-size    : 13px;
  border-radius: 4px;
  position     : absolute;
  margin-top   : 40px;
  opacity      : 0;
  transition   : opacity .3s
}

div.field:hover label.error {
  opacity: 1 !important;
}

div.coverage-field:hover label.error {
  opacity: 1 !important;
}

#arrow,
#arrow::before {
  position  : absolute;
  width     : 8px;
  height    : 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content   : '';
  transform : rotate(45deg);
}

#tooltip[data-popper-placement^='top']>#arrow {
  bottom: -4px;
}

.actions.clearfix {
  display: none !important;
}

.show-flex {
  opacity  : 1 !important;
  transform: translateY(calc(-50% + 47.5px)) translateX(-50%) !important;
}

.completed-wrapper {
  transition         : .5s all !important;
  display            : flex !important;
  flex-direction     : column;
  /* padding         : 50px; */
  /* border-radius   : 30px; */
  /* box-shadow      : 0px 23px 45px 0px rgb(18 42 78 / 10%); */
  /* opacity         : 0; */
  /* position        : absolute !important;
  top                : 50% !important;
  left               : 50% !important; */
  /* width           : 860px; */
  /* transform       : translateY(150%) translateX(-50%); */
  /* background-color: #fffc; */
}

.completed-wrapper .success-heading {
  font-size  : 40px;
  color      : #1a75bb;
  font-weight: 800;
  text-align : center;
  line-height: 1;
}

.completed-wrapper .check-img {
  max-width: 100px;
  margin   : 25px auto 20px;
}

.completed-wrapper .text {
  font-size     : 24px;
  font-weight   : 400;
  /* color      : #28a745; */
  line-height   : 1.3;
  text-align    : center;
}

.completed-wrapper .icon-sm-loader {
  width      : 20px;
  height     : 20px;
  margin-left: 10px;
  margin-top : 8px;
  display    : inline;
}

/* ICONS ANIMATION */
.checkmark__circle {
  stroke-dasharray : 166;
  stroke-dashoffset: 166;
  stroke-width     : 2;
  stroke-miterlimit: 10;
  stroke           : #7ac142;
  fill             : none;
  animation        : stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.circlemark__circle {
  stroke-dasharray : 166;
  stroke-dashoffset: 166;
  stroke-width     : 2;
  stroke-miterlimit: 10;
  stroke           : #1d77b9;
  fill             : none;
  animation        : stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width            : 56px;
  height           : 56px;
  border-radius    : 50%;
  display          : block;
  stroke-width     : 2;
  stroke           : #fff;
  stroke-miterlimit: 10;
  box-shadow       : inset 0px 0px 0px #7ac142;
  animation        : fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  position         : absolute;
  right            : 0px;
  top              : -8px;
}

.circlemark {
  width               : 56px;
  height              : 56px;
  border-radius       : 50%;
  display             : block;
  stroke-width        : 2;
  stroke              : #fff;
  stroke-miterlimit   : 10;
  fill                : #1d77b9;
  box-shadow          : inset 0px 0px 0px 30px #1d77b9;
  /* animation        : fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both; */
  position            : absolute;
  right               : 0px;
  top                 : -8px;
}

.checkmark__check.animateElement {
  transform-origin : 50% 50%;
  stroke-dasharray : 48;
  stroke-dashoffset: 48;
  animation        : stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.crossmark__circle.animateElement {
  stroke-dasharray : 166;
  stroke-dashoffset: 166;
  stroke-width     : 2;
  stroke-miterlimit: 10;
  stroke           : #1d77b9;
  fill             : #1d77b9;
  animation        : stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.crossmark.animateElement {
  width            : 55px;
  height           : 56px;
  position         : absolute;
  right            : 0px;
  top              : -8px;
  border-radius    : 50%;
  display          : block;
  stroke-width     : 2;
  /* stroke        : #ff0000; */
  stroke-miterlimit: 10;
  /* box-shadow    : inset 0px 0px 0px #ff0000; */
  animation        : fillred .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.crossmark__check.animateElement {
  transform-origin : 50% 50%;
  stroke-dasharray : 48;
  stroke-dashoffset: 48;
  animation        : stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.cross__path.animateElement {
  stroke           : #ffffff;
  stroke-dasharray : 48;
  stroke-dashoffset: 48;
  transform-origin : 50% 50% 0;
}

.cross__path--right.animateElement {
  animation: 0.3s ease 0.8s normal forwards 1 running stroke;
}

.cross__path--left.animateElement {
  animation: 1s ease 0.8s normal forwards 1 running stroke;
}

@keyframes fillred {
  100% {
    box-shadow: inset 0px 0px 0px 30px #ff0000;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

.circular-loader {
  animation               : rotate 2s linear infinite;
  height                  : 40px;
  transform-origin        : center center;
  width                   : 40px;
  position                : absolute;
  top                     : 0;
  left                    : 0;
  margin                  : auto;
}

.loader-path {
  stroke-dasharray : 150, 200;
  stroke-dashoffset: -10;
  animation        : dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap   : round;
}

@keyframes rotate {
  100% {
    transform        : rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray : 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray : 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray : 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes color {
  0% {
    stroke: #ffffff;
  }

  40% {
    stroke: #ffffff;
  }

  66% {
    stroke: #ffffff;
  }

  80%,
  90% {
    stroke: #ffffff;
  }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
  .steps-nav .logo {
    margin-left: 15px;
    width      : 160px;
  }

  .steps-nav .contact-btn {
    padding     : 6px 12px 5px;
    font-size   : 14px;
    margin-right: 15px;
  }

  .step.forth {
    zoom: 0.6 !important;
    top : 53% !important;
  }

  .steps-nav .contact-btn img {
    width: 20px;
  }

  .main .step {
    width         : 90%;
    padding       : 50px 10px 45px 10px;
    zoom          : 0.8;
    -moz-transform: scale(0.8, 0.8);
    margin        : auto;
  }

  .main .step .sm-heading {
    font-size  : 15px;
    line-height: 1.3;
  }

  .main .step .lg-heading {
    font-size  : 26px;
    line-height: 1.2;
  }

  .main .step .lg-heading br {
    display: none;
  }

  .main .step.first .field {
    display     : block;
    margin-right: 0;
  }

  .main .step.first .field input {
    min-width       : inherit;
    width           : auto;
    height          : 55px;
    color           : #222;
    padding         : 0 15px;
    background-color: #fff;
    border          : 1px solid #e0e0da;
    margin-bottom   : 25px;
  }

  .main .step.third .code {
    display: block;
  }

  .main .step.third .code .resend-code,
  .main .step.fifth .code .resend-code {
    display    : inline-block;
    margin-left: 0;
    margin-top : 20px;
  }

  .main .step.second .signupbutton_content .row-f {
    display: block;
  }

  .main .step.second .signupbutton_content a {
    width: 100%;
  }

  .main .step.forth .forth-content .work {
    width: 100%;
  }

  .main .step.sixth .sixth-content .looking-for .item {
    margin-bottom: 25px;
  }

  .custom-cb+label {
    font-size   : 18px;
    padding-left: 45px;
  }

  .custom-cb+label:before {
    top   : 0;
    width : 30px;
    height: 30px;
  }

  .custom-cb:checked+label:after {
    left: 6px;
    top : 16px;
  }

  .main .step .footer .pe {
    display: none;
  }

  .wizard>.content {
    padding: 0 !important;
  }

  .completed-wrapper {
    width    : 100%;
    max-width: 100%;
    padding  : 30px 25px;
  }

  .completed-wrapper .success-heading {
    font-size: 35px;
  }

  .completed-wrapper .check-img {
    max-width: 80px;
    margin   : 20px auto 15px;
  }

  .completed-wrapper .text-green {
    font-size: 21px;
  }

  .checkmark-wrapper,
  .crossmark-wrapper,
  .circlemark-wrapper {
    height: auto;
    width : 100%;
    top   : 5px;
    right : 5px;
  }

  .checkmark-wrapper .message,
  .crossmark-wrapper .message,
  .circlemark-wrapper .message {
    font-size   : 13px;
    padding     : 5px 30px;
    margin-right: -15px;
  }

  .crossmark.animateElement {
     width : auto;
    height: -webkit-fill-available;

  }
  .signatureConfirmed{
    font-size: 40px!important;
  }

  .main .first-step .coverage-field,
  .forth .coverage-field {
    display: block !important;
    width  : 100% !important;
  }

  .add_another_number_field {
    display: block !important;
  }

  .wizard>.steps>ul>li {
    width: 25% !important;
  }

  .step2-fields .select-label {
    font-size: 16px !important;
  }

  .add_another_number_field .select-label {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1399px) and (min-width: 768px) {
  .main .step {
    zoom: 0.8;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1400px) {
  .main .step {
    zoom: 0.9;
  }
}

.number-loader {
  display: none;
  margin : 10px 15px !important;
}

.main .step.third .code .digits input[type=number] {
  position: absolute;
  z-index : -1;
  opacity : 0;
}

.phoneMaskContinue {
  background-color: #3674ba;
  padding         : 0px 5px;
  color           : #fff;
  border          : none;
  padding-right   : 10px;
}

.phoneMaskContinue svg {
  width    : 50px;
  height   : 30px;
  transform: rotate(50deg);
}

.phoneMaskContinue.dontRotate svg {
  transform: rotate(0deg);
}

.showNumbersButton {
  background-color: #3674ba;
  padding         : 0px 5px;
  color           : #fff;
  border          : none;
  padding-right   : 10px;
}

.showNumbersButton svg {
  width    : 50px;
  height   : 30px;
  transform: rotate(50deg);
}

#timer {
  font-size  : 22px;
  color      : #4573b8;
  font-weight: bold;
  margin-left: 20px;
}

.shakey {
  animation                : shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    margin-left: -2px;
  }

  10% {
    margin-left: 2px;
  }

  20% {
    margin-left: -2px;
  }

  30% {
    margin-left: 3px;
  }

  40% {
    margin-left: -3px;
  }

  50% {
    margin-left: 3px;
  }

  60% {
    margin-left: -2px;
  }

  70% {
    margin-left: 2px;
  }

  80% {
    margin-left: -3px;
  }

  90% {
    margin-left: -2px;
  }

  100% {
    margin-left: 1px;
  }
}

.pincode-input-container {
  width: 300px;
}

.pin-correct {
  width   : 30px;
  position: absolute;
  top     : -10px;
  right   : -10px;
}

.loader-success {
  border           : 5px solid #ddd;
  border-radius    : 50%;
  border-top       : 5px solid #1d77b9;
  width            : 30px;
  height           : 30px;
  /* margin        : 0 auto; */
  animation        : spin 2s linear infinite;
  margin           : 10px auto;
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ///////////////////////////////////////
COVERAGE PAGE CSS */
/* .country-dropdown .work {
  width: auto;
  height: 45px;
  font-size: 18px;
  font-weight: 400;
  color: #8b8b8b !important;
  padding-left: 10px;
  background-color: #fff !important;
  border: 1px solid #dbdbd9;
  border-radius: 0;
  box-sizing: border-box;
  outline: 0;
  box-shadow: none;
  margin-bottom: 0;
}  */
.services-dropdown .coverage-field {
  width: 100%;
}

.phone-number input {
  width        : auto;
  height       : 45px;
  font-size    : 18px;
  font-weight  : 400;
  color        : #000 !important;
  padding-left : 10px;
  border-bottom: 1px solid #dbdbd9;
  border-radius: 0;
  box-sizing   : border-box;
  outline      : 0;
  box-shadow   : none;
  margin-bottom: 0;
}

.forth .coverage-field {
  display: inline-block;
  width  : 49%;
}

.main .coverage-field input {
  width        : 93%;
  border       : 0px;
  border-bottom: 2px solid #ccc;
  background   : transparent;
  border-radius: 0px;
  box-shadow   : none;
  font-size    : 18px;
  padding      : 10px 10px;
  height       : 40px;
}

.main .first-step .order-field {
  display: inline-block;
  width  : 33%;
}

/* .main .second-step .coverage-field input {
  min-width: auto;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  color: #222;
  background-color: transparent;
  line-height: 1;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #e0e0da;
  outline: 0;
  box-shadow: none;
} */
/* .main .second-step .coverage-field {
  display: inline-block;
  margin-right: 20px;
} */
#form1 input,
#form2 input {
  width        : 80%;
  border       : 0px;
  border-bottom: 2px solid #ccc;
  background   : transparent;
  border-radius: 0px;
  box-shadow   : none;
  font-size    : 18px;
  padding      : 10px 10px;
  height       : 40px;
}

.coverage-field.address-field {
  width: 100% !important;
}

.coverage-field.city-field {
  width: 28% !important;
}

.coverage-field.zipcode-field {
  width: 20% !important;
}

.coverage-field.country-field {
  width: 50% !important;
}

.coverage-field.email-field input {
  width  : 95% !important;
  padding: 10px 0px 10px 10px !important;
}

.step2-fields .select-label,
.coverage-field .select-label {
  font-size     : 18px;
  font-weight   : 400;
  color         : #1a75bb;
  line-height   : 1.2;
  letter-spacing: -0.5px;
  margin-bottom : 20px;
}

.step2-fields select,
.add_another_number_field select {
  width           : 220px;
  border          : 0px;
  border-bottom   : 2px solid #1a75bb;
  background-color: transparent;
  border-radius   : 0px;
  font-size       : 16px;
  height          : 35px;
  color           : #000 !important;
}

.step2-fields select:focus {
  outline: none !important;
}

.second-step .mt-25 .coverage-field,
.second-step .mt-25 .step2-fields {
  display: inline;
  margin : 5px;
}

.thirteen .mt-25 .coverage-field,
.thirteen .mt-25 .step2-fields {
  display: inline;
  margin : 5px;
}

.main .step .footer .login-btn {
  min-width       : 160px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  padding         : 10px 20px;
  font-size       : 21px;
  font-weight     : 700;
  color           : #fff;
  text-align      : center;
  text-decoration : none;
  text-transform  : uppercase;
  border          : 0;
  border-radius   : 30px;
  background-color: rgb(255, 69, 0);
  box-shadow      : 0px 15px 35px 0px rgb(255 66 0 / 32%);
  box-sizing      : border-box;
  margin-right    : 30px;
  cursor          : pointer;
  transition      : all 0.3s;
}

.signin_text {
  text-align: center;
  margin-top: 30px;
}

.signin_text span {
  font-size: 13px;
}

.add_another_number_field .services-dropdown.coverage-field {
  margin-right: 12px !important;
  margin-left : 10px;
}

.add_another_number_field {
  display    : flex;
  align-items: baseline;
}

p.dotted_line {
  text-align: center;
  margin    : 0px;
}

.main .step .login_continue {
  display        : flex;
  align-items    : center;
  margin-top     : 40px;
  justify-content: center;
}

.girl-phone {
  position  : absolute;
  bottom    : -18px;
  left      : -100px;
  max-height: 85vh;
}

.input-container {
  display      : flex;
  width        : 100%;
  margin-bottom: 15px;
  background   : rgba(95, 170, 255, 0.2);
  border-bottom: none;
  box-shadow   : none;
  outline      : none;
  width        : 100%;
  height       : 56px;
  font-size    : 24px;
  font-weight  : 400;
}

.input-container input {
  border-bottom: none !important;
  height       : 100% !important;
}

.input-container .icon-c {
  background     : #337ab7;
  color          : #fff;
  text-align     : center;
  font-size      : 18px;
  min-width      : 56px;
  height         : 56px;
  font-size      : 25px;
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.input-container .input-field {
  width      : 100%;
  font-size  : 20px;
  padding    : 5px;
  outline    : none;
  border     : 1px solid #000;
  border-left: none;
  color      : #000;
}

.input-container .input-field.error {
  background: rgba(255, 80, 80, 0.398) !important;
}

a.edit_form {
  background   : none;
  border       : 0px;
  font-size    : 14px;
  margin-bottom: 10px;
  color        : #337ab7;
  float        : right;
  cursor       : pointer;
}

/* .cat {
  margin: 4px;
} */
.cat.action {
  /* width: 100%; */
  margin-bottom: 10px;
}

.cat label span {
  text-align: center;
  padding   : 3px 0;
  display   : block;
}

.cat label input {
  position: absolute;
  display : none !important;
  color   : #fff !important;
}

.cat label input+span {
  padding         : 10px 0px;
  position        : relative;
  display         : flex;
  align-items     : center;
  font-size       : 20px;
  font-weight     : 400;
  color           : #8b8b8b;
  text-decoration : none;
  text-transform  : capitalize;
  background-color: #fff;
  border          : 1px solid #dbdbd9;
  border-radius   : 30px;
  margin          : 0px 10px;
  transition      : all 0.3s;
  justify-content : center;
  cursor          : pointer;
}

.cat input:checked+span {
  color: #ffffff;
}

.sm-text {
  font-size  : 16px;
  font-weight: 400;
  color      : #222;
  line-height: 1;
  margin     : 25px 0;
}

.cat img.icon {
  padding-right: 6px;
}

.wizard>.content>.body .cat label {
  display: block !important;
}

.mark.action input:checked+span:after {
  content          : '';
  position         : absolute;
  right            : -10px;
  top              : -10px;
  z-index          : 1;
  font-size        : 26px;
  height           : 20px;
  padding          : 15px;
  background-image : url("../img/eo_circle_green_checkmark.png");
  background-repeat: no-repeat;
  background-size  : contain;
  animation        : pulse 1s ease-in-out alternate;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: #d3d0d0 !important;
}

/* ////////////////////// */
/* ORDER FORM CSS */
/* /////////////////// */
.container1 .radioLabel {
  font-size: 18px;
}

.container1 {
  display        : flex;
  justify-content: space-evenly;
  align-content  : center;
  align-items    : center;
}

input[type="radio"] {
  margin: 0px 10px 0px 0px;
  width : 20px;
}

button.btn.dropdown-toggle.btn-default {
  font-size    : 18px;
  height       : 50px;
  width        : 100%;
  background   : #fff;
  outline      : 0px !important;
  border       : 0px;
  border-bottom: 2px solid #ccc;
  border-radius: 0px;
  box-shadow   : none;
}

.dropup .dropdown-menu {
  top   : 100% !important;
  bottom: auto !important;
}

.dropdown-menu.open.show {
  transform: none !important;
  top      : auto !important;
}

label.radioLabel {
  margin: 10px;
}

.main .first-step .order-field {
  display: inline-block;
  width  : 48%;
}

.btn-group.bootstrap-select.countrypicker.f16 {
  width: 95%;
}

.main .first-step .order-field input {
  width        : 100%;
  border       : 0px;
  border-bottom: 2px solid #ccc;
  background   : #fff;
  border-radius: 0px;
  box-shadow   : none;
  font-size    : 18px;
  padding      : 10px 10px;
  height       : 50px;
}

input[type="radio"] {
  float: left;
}

p.radio-heading {
  font-size  : 22px;
  margin     : 5px;
  font-weight: 600;
  color      : #1a75c6;
}

.radio-toolbar {
  margin         : 10px;
  display        : flex;
  justify-content: center;
}

p.radio_heading {
  font-size  : 22px;
  margin     : 5px 0px;
  font-weight: 600;
}

.features-content .radio input[type=checkbox]+.radio-label {
  display    : flex;
  align-items: center;
  color      : #000 !important;
}

.features-content .radio {
  padding-left: 0px;
  display     : inline-block;
}

.features-content .radio input:checked+.radio-label:after {
  content   : "";
  position  : absolute;
  left      : 18px;
  top       : 12px;
  background: #52719c;
  width     : 7px;
  height    : 7px;
  box-shadow: -3px 0 0 #52719c, 4px 0 0 #52719c, 4px -2px 0 #52719c, 4px -14px 0 #52719c, 4px -20px 0 #52719c, 4px -8px 0 #52719c;
  transform : rotate(45deg);
}

.features-content .radio input:checked+.radio-label.error:after {
  background: rgba(255, 80, 80, 0.398);
}

.features-content .radio input[type=checkbox] {
  opacity: 0;
}

.features-content .radio input+.radio-label:before {
  content   : "";
  position  : absolute;
  top       : -8px;
  left      : 8px;
  width     : 40px;
  height    : 40px;
  background: white;
  border    : 1px solid #b4b4b4;
}

.features-content .radio input+.radio-label {
  position     : relative;
  font-size    : 21px;
  font-weight  : 400;
  color        : #8b8b8b;
  cursor       : pointer;
  padding-left : 55px;
  line-height  : 1.2;
  margin-bottom: 0 !important;
}

/* input#PhoneMask {
  width: 50% !important;
} */
/*
.iti.iti--allow-dropdown {
  width: 100%;
} */
h2.form_heading {
  font-size: 25px;
  color    : #1a75bb;
}

.wizard>.content>.body .phone-number label.error {
  display      : inline-block;
  background   : #ffffff;
  color        : #643045;
  font-weight  : bold;
  padding      : 5px 10px;
  font-size    : 13px;
  border-radius: 4px;
  position     : absolute;
  margin-top   : 40px;
  opacity      : 0;
  transition   : opacity .3s;
}

.form-a,
.form-b {
  transition: all;
}

.form-a .lg-heading,
.form-b .lg-heading {
  font-size     : 25px;
  font-weight   : 400;
  color         : #1a75bb;
  line-height   : 1.2;
  letter-spacing: -0.5px;
  margin        : 20px 0px;
}

.fifth-content .radio {
  display: inline-block;
}

.fifth-content input {
  min-width       : 265px;
  height          : 55px;
  font-size       : 24px;
  font-weight     : 500;
  color           : #222;
  background-color: #fff;
  line-height     : 1;
  text-transform  : capitalize;
  margin          : 0;
  padding         : 0 0 0 55px;
  border          : 0;
  border-radius   : 0;
  border          : 1px solid #e0e0da;
  outline         : 0;
  box-shadow      : none;
}

.QA_content {
  display    : flex;
  align-items: center;
  margin-top : 0px;
}

.QA_content .radio {
  margin-right: 70px;
  padding-left: 0;
}

.QA_content .radio input[type=radio] {
  position: absolute;
  opacity : 0;
  width   : 90px;
  z-index : 1;
  height  : 40px;
}

.QA_content .radio input[type=radio]+.radio-label {
  display       : flex;
  align-items   : center;
  margin-bottom : 0;
  font-size     : 24px;
  font-weight   : 400;
  color         : #8b8b8b !important;
  text-transform: uppercase;
}

.QA_content .radio input[type=radio]:checked+.radio-label:before {
  background-color: #52719c;
  box-shadow      : inset 0 0 0 6px #f4f4f4;
}

.QA_content .radio input[type=radio]+.radio-label:before {
  content       : "";
  background    : #fff;
  border-radius : 13px;
  border        : 1px solid #b4b4b4;
  display       : inline-block;
  width         : 35px;
  height        : 35px;
  margin-right  : 15px;
  vertical-align: top;
  cursor        : pointer;
  text-align    : center;
  transition    : all 250ms ease;
}

.QA_content .radio input+.radio-label {
  position     : relative;
  font-size    : 21px;
  font-weight  : 400;
  color        : #8b8b8b;
  cursor       : pointer;
  line-height  : 1.2;
  margin-bottom: 0 !important;
}

.carrier_check label {
  text-transform: capitalize !important;
}

.sixth .code_card {
  width  : 400px;
  border : none;
  height : auto;
  z-index: 1;
}

.sixth .code_card h6 {
  color    : red;
  font-size: 20px
}

.sixth .inputs input {
  width : 40px;
  height: 40px
}

.sixth input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance        : none;
  margin            : 0
}

.sixth .card-2 .content a {
  color: red
}

.sixth .form-control:focus {
  box-shadow: none;
  border    : 2px solid red
}

.sixth #otp input {
  display: inline-block !important;
}

.tenth .order-field input {
  width        : 93%;
  border       : 0px;
  border-bottom: 2px solid #ccc;
  background   : #fff;
  border-radius: 0px;
  box-shadow   : none;
  font-size    : 18px;
  padding      : 10px 10px;
  height       : 30px;
}

.tool_tip {
  width        : 100%;
  background   : #fff;
  padding      : 20px 20px;
  box-shadow   : 4px 5px 20px 0px #3333334f;
  border-radius: 30px;
  margin-top   : 20px;
}

.tool_tip::after {
  content   : '';
  width     : 45px;
  background: #fff;
  height    : 45px;
  z-index   : -1;
  position  : absolute;
  left      : 60px;
  bottom    : 150px;
  transform : rotate(136deg);
}

.phone_account .radio {
  margin-right: 70px;
  padding-left: 0;
}

.phone_account .radio input[type=radio] {
  position: absolute;
  opacity : 0;
  width   : 90px;
  z-index : 1;
  height  : 40px;
}

.phone_account .radio input[type=radio]+.radio-label {
  display       : flex;
  align-items   : center;
  margin-bottom : 10px !important;
  font-size     : 18px;
  font-weight   : 400;
  color         : #8b8b8b !important;
  text-transform: capitalize;
}

.phone_account .radio input[type=radio]:checked+.radio-label:before {
  background-color: #52719c;
  box-shadow      : inset 0 0 0 6px #f4f4f4;
}

.phone_account .radio input[type=radio]+.radio-label:before {
  content       : "";
  background    : #fff;
  border-radius : 13px;
  border        : 1px solid #b4b4b4;
  display       : inline-block;
  width         : 35px;
  height        : 35px;
  margin-right  : 15px;
  vertical-align: top;
  cursor        : pointer;
  text-align    : center;
  transition    : all 250ms ease;
}

.phone_account .radio input+.radio-label {
  position     : relative;
  font-size    : 21px;
  font-weight  : 400;
  color        : #8b8b8b;
  cursor       : pointer;
  line-height  : 1.2;
  margin-bottom: 0 !important;
}

.tool_tip p {
  font-size: 16px;
  margin   : 0px;
}

input#PhoneMask {
  width: 100%;
}

@media screen and (max-width:600px) {
  .main .first-step .order-field {
    width : 100%;
    margin: 10px;
  }

  .features-content .radio {
    display: block !important;
  }

  input#PhoneMask {
    width: 100% !important;
  }

  .QA_content {
    display: block;
  }

  .QA_content .radio {
    padding: 5px;
  }

  button#button2,
  button#button1 {
    margin: 15px 0px;
    width : 80%;
  }

  .main .step .footer .proceed-btn {
    width: 80%;
  }

  .main .step .footer {
    display: block;
  }

  .btn-group.bootstrap-select.countrypicker.f16 {
    width: 100%;
  }

  .completed-wrapper .text {
    font-size: 16px;
  }

  .popup-bg {
    left: 250px !important;
  }

  .popup-outline {
    left: 250px !important;
  }

  .popup-text {
    left: 270px !important;
  }
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 15%) !important;
  outline   : 0px !important;
}

.accordion-header {
  margin-top: 0px;
  display   : none;
}

.first-step .accordion-item {
  background-color: #fff0 !important;
  border          : 0px;
}

.accordion-button {
  background-color: #fff0 !important;
  font-size       : 25px !important;
  color           : #1a75bc !important;
}

.accordion-button:focus {
  border-color: #fff0 !important;
  box-shadow  : none !important;
}

button.accordion-button {
  display: none;
}

button#button2,
button#button1 {
  min-width       : 160px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  padding         : 10px 20px;
  font-size       : 18px;
  font-weight     : 700;
  color           : #fff;
  text-align      : center;
  text-decoration : none;
  text-transform  : uppercase;
  border          : 0;
  border-radius   : 30px;
  background-color: rgb(255, 69, 0);
  box-shadow      : 0px 15px 35px 0px rgb(255 66 0 / 32%);
  box-sizing      : border-box;
  margin-right    : 30px px;
  cursor          : pointer;
  transition      : all 0.3s;
}

button#button2:hover,
button#button1:hover {
  background-color: #51709c;
  transition      : all 0.3s;
}

.eleventh .order-field input,
.fifteen-3 .order-field input,
.fifteen .order-field input,
.tenth .order-field input {
  width        : 100%;
  border       : 0px;
  border-bottom: 2px solid #ccc;
  background   : #fff;
  border-radius: 0px;
  box-shadow   : none;
  font-size    : 18px;
  padding      : 10px 10px;
  height       : 50px;
  outline      : none;
}

.wizard>.content>.body label.error {
  display      : inline-block;
  background   : #ffffff;
  color        : #643045;
  font-weight  : bold;
  padding      : 5px 10px;
  font-size    : 13px;
  border-radius: 4px;
  position     : absolute;
  bottom       : -40px;
  opacity      : 0;
  transition   : opacity .3s;
}

input:hover label.error {
  opacity: 1 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.tenth .text {
  box-sizing: border-box;
  padding   : 0px 0px 30px 0px;
  width     : 100%;
}

.tenth .title {
  align-items  : center;
  display      : flex;
  font-size    : 30px;
  font-weight  : 400;
  margin-bottom: 4px;
  position     : relative;
  color        : #1a75bb;
  line-height  : 1.2;
}

.tenth .tooltip {
  font-size  : 16px;
  font-weight: normal;
  margin-top : 2px;
  position   : relative;
  opacity    : 1 !important;
  color      : #000;
}

.tenth .tooltip:hover {
  cursor: help;
}

.tenth .tooltip-spacing {
  height  : 20px;
  margin  : 8px;
  position: relative;
}

.tenth .tooltip-text {
  font-size  : 14px;
  font-weight: bold;
  line-height: 20px;
  position   : relative;
  text-align : center;
}

.tenth .tooltip-text img {
  width : auto;
  height: auto;
}

.tenth .popup-bg {
  filter  : drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16)) drop-shadow(0 3px 6px rgba(0, 0, 0, 0.23));
  position: absolute;
  top     : -126px;
  left    : 430px;
}

.tenth .popup-outline {
  position: absolute;
  top     : -126px;
  left    : 430px;
}

.tenth .popup-text {
  border-radius: 12px;
  box-sizing   : border-box;
  color        : #fff;
  font-size    : 16px;
  font-weight  : normal;
  left         : 450px;
  opacity      : 0;
  padding      : 12px 16px;
  position     : absolute;
  top          : -110px;
  transition   : opacity 240ms 120ms cubic-bezier(0.4, 0, 0.2, 1);
  width        : 292px;
}

.tenth .tooltip:hover~.popup-text {
  display: block;
}

.tenth .popup-bg {
  opacity   : 0;
  transition: opacity 240ms 120ms cubic-bezier(0.4, 0, 0.2, 1);
}

.tenth .popup-outline-left {
  stroke-dasharray : 0 426px;
  stroke-dashoffset: 1px;
  transition       : stroke-dasharray 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.tenth .popup-outline-right {
  stroke-dasharray : 352px 352px;
  stroke-dashoffset: -352px;
  transition       : stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.tenth .tooltip:hover~.popup-text {
  opacity: 1;
}

.tenth .tooltip:hover~.popup-bg {
  opacity   : 1;
  transition: opacity 240ms 120ms cubic-bezier(0.4, 0, 0.2, 1);
}

.tenth .tooltip:hover~.popup-outline .popup-outline-left {
  stroke-dasharray: 426px 426px;
  transition      : stroke-dasharray 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.tenth .tooltip:hover~.popup-outline .popup-outline-right {
  stroke-dashoffset: 0;
  transition       : stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.third .text {
  box-sizing: border-box;
  padding   : 0px 0px 30px 0px;
  width     : 100%;
}

.third .title {
  align-items  : center;
  display      : flex;
  font-size    : 30px;
  font-weight  : 400;
  margin-bottom: 4px;
  position     : relative;
  color        : #1a75bb;
  line-height  : 1.2;
}

.third .tooltip {
  font-size  : 16px;
  font-weight: normal;
  margin-top : 2px;
  position   : relative;
  opacity    : 1 !important;
  color      : #000;
}

.third .tooltip:hover {
  cursor: help;
}

.third .tooltip-spacing {
  height  : 20px;
  margin  : 8px;
  position: relative;
}

.third .tooltip-text {
  font-size  : 14px;
  font-weight: bold;
  line-height: 20px;
  position   : relative;
  text-align : center;
}

.third .tooltip-text img {
  width : auto;
  height: auto;
}

.third .popup-bg {
  filter  : drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16)) drop-shadow(0 3px 6px rgba(0, 0, 0, 0.23));
  position: absolute;
  top     : -126px;
  left    : 430px;
}

.third .popup-outline {
  position: absolute;
  top     : -126px;
  left    : 430px;
}

.third .popup-text {
  border-radius: 12px;
  box-sizing   : border-box;
  color        : #fff;
  font-size    : 16px;
  font-weight  : normal;
  left         : 450px;
  opacity      : 0;
  padding      : 12px 16px;
  position     : absolute;
  top          : -110px;
  transition   : opacity 240ms 120ms cubic-bezier(0.4, 0, 0.2, 1);
  width        : 292px;
}

.third .tooltip:hover~.popup-text {
  display: block;
}

.third .popup-bg {
  opacity   : 0;
  transition: opacity 240ms 120ms cubic-bezier(0.4, 0, 0.2, 1);
}

.third .popup-outline-left {
  stroke-dasharray : 0 426px;
  stroke-dashoffset: 1px;
  transition       : stroke-dasharray 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.third .popup-outline-right {
  stroke-dasharray : 352px 352px;
  stroke-dashoffset: -352px;
  transition       : stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.third .tooltip:hover~.popup-text {
  opacity: 1;
}

.third .tooltip:hover~.popup-bg {
  opacity   : 1;
  transition: opacity 240ms 120ms cubic-bezier(0.4, 0, 0.2, 1);
}

.third .tooltip:hover~.popup-outline .popup-outline-left {
  stroke-dasharray: 426px 426px;
  transition      : stroke-dasharray 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.third .tooltip:hover~.popup-outline .popup-outline-right {
  stroke-dashoffset: 0;
  transition       : stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.radio-group .pic {
  margin-top   : 30px;
  margin-bottom: 20px;
}

.radio-group .card-block {
  width           : 230px;
  border          : 1px solid lightgrey;
  border-radius   : 20px !important;
  background-color: #FAFAFA;
}

.radio-group .radio {
  display       : inline-block;
  border-radius : 0;
  border        : 2px solid #fff0;
  box-sizing    : border-box;
  cursor        : pointer;
  color         : #000;
  font-weight   : 500;
  -moz-filter   : grayscale(100%);
  -o-filter     : grayscale(100%);
  -ms-filter    : grayscale(100%);
  filter        : grayscale(100%);
  padding       : 10px 10px !important;
}

.radio-group .radio:hover {
  box-shadow: 2px 2px 10px 2px rgb(0 0 0 / 10%);
  transition: .5s;
}

.radio-group .radio.selected {
  box-shadow    : 0px 0px 20px 0px #ff450085;
  -moz-filter   : grayscale(0%);
  -o-filter     : grayscale(0%);
  -ms-filter    : grayscale(0%);
  filter        : grayscale(0%);
  border        : 2px solid #ff4500;
  transition    : .3s;
}

.radio-group .a {
  justify-content: center !important;
}

.radio-group .btn {
  border-radius: 0px;
}

.radio-group .btn,
.radio-group .btn:focus,
.radio-group .btn:active {
  outline   : none !important;
  box-shadow: none !important;
}

.random_number {
  background   : #fff;
  border-radius: 5px;
  text-align   : center;
  font-size    : 20px;
}

.reload_numbers {
  display           : block;
  margin-left       : auto;
  transition        : transform 5s;
  cursor            : pointer;
  position          : absolute;
  right             : 0;
  top               : 30px;
}

.reload_numbers:hover {
  transform        : rotate(360deg);
  -moz-transform   : rotate(360deg);
  -webkit-transform: rotate(360deg);
}

button#button-addon2 {
  background: #1a75bb;
  z-index: 0;
}

.form-control:focus,
.btn:focus {
  box-shadow: none;
}

span#addon-wrapping {
  background: #186cac;
}

.fa-map-pin:before {
  color    : #fff;
  font-size: 22px;
}

.other_main_countries {
  background: #fff;
  padding   : 10px;
  height    : 150px;
  overflow-y: scroll;
}

.other_main_countries .cat label input+span {
  font-size: 18px !important;
}

.other_main_countries .action input:checked+span {
  font-size: 18px !important;
}

.other_main_countries::-webkit-scrollbar {
  width           : 10px;
  background-color: rgba(255, 69, 0, 0.2) !important;
}

.other_main_countries::-webkit-scrollbar-thumb {
  background-color: rgb(255, 69, 0);
  border-radius   : 10px;
}

.two_main_countries .cat.action {
  width: 30%;
}

.other_main_carriers {
  background: #fff;
  padding   : 10px;
  height    : 200px;
  overflow-y: scroll;
}

.other_main_carriers .row {
  width: 100%;
}

.other_main_carriers .cat label input+span {
  font-size: 18px !important;
}

.other_main_carriers .action input:checked+span {
  font-size: 18px !important;
}

.other_main_carriers::-webkit-scrollbar {
  background-color: rgba(255, 69, 0, 0.2) !important;
  width           : 10px;
}

.other_main_carriers::-webkit-scrollbar-thumb {
  background-color: #ff4500;
  border-radius   : 10px;
}

.code_verification .cat label input+span,
.three_main_carriers .cat label input+span {
  font-size: 18px !important;
}

.forth .action input:checked+span,
.eight .action input:checked+span,
.ninth .action input:checked+span {
  box-shadow      : 0px 0px 10px 2px #ff450085;
  background-color: #fff !important;
  border-color    : #ff4500;
}

.step .action input.disabled+span {
  opacity: 0.6;
}

.step .action input:checked+span {
  box-shadow      : 0px 0px 10px 2px #ff4500;
  background-color: #fff !important;
  border-color    : #ff4500;
}

.three_main_carriers .action input:checked+span:after,
.two_main_countries .action input:checked+span:after {
  content   : none;
  box-shadow: 2px 2px 10px 2px #00000066;
}

.code_verification .action input:checked+span:after {
  content   : none;
  box-shadow: 2px 2px 10px 2px #00000066;
}

.other_main_carriers .action input:checked+span:after,
.other_main_countries .action input:checked+span:after {
  content   : none;
  box-shadow: 2px 2px 10px 2px #00000066;
}

input#scheduleInput {
  height    : 55px;
  border    : 1px solid #dbdbd9;
  width     : 100%;
  padding   : 20px 15px;
  margin-top: 15px;
  border    : 0px;
  font-size : 18px;
  color     : #000;
}

.myDiv {
  display: none;
}

.showOne {
  display: block;
}

.mid_text p {
  font-size    : 30px;
  font-weight  : 700;
  color        : #1a75bc;
  margin       : 0px;
  padding      : 15px;
  border       : 5px solid #ff4500;
  border-radius: 100%;
}

.wizard>.content>.body input {
  height: 55px;
}

.dropdown dd,
.dropdown dt,
.dropdown ul {
  margin : 0px;
  padding: 0px;
}

.dropdown dd {
  position: relative;
}

.dropdown a,
.dropdown a:visited {
  color          : #6c757d;
  text-decoration: none;
  outline        : none;
}

.dropdown a:hover {
  color: #000;
}

.dropdown dt a:hover {
  color: #000;
}

.dropdown dt a {
  background     : #fff url('../img/down-arrow.png') no-repeat scroll right center;
  display        : block;
  padding-right  : 20px;
  border         : none;
  background-size: 20px;
  border-radius  : 4px;
  border         : 1px solid #ced4da;
}

.dropdown dt a {
  height     : 55px;
  display    : flex;
  align-items: center
}

.dropdown dt a span {
  cursor    : pointer;
  display   : inline;
  padding   : 11px;
  margin-top: 5px;
}

.dropdown dd ul {
  background: #fff none repeat scroll 0 0;
  border    : none;
  color     : #000;
  display   : none;
  left      : 0px;
  padding   : 5px 0px;
  position  : absolute;
  top       : 0px;
  width     : 100%;
  min-width : 50%;
  list-style: none !important;
  z-index   : 9;
}

.dropdown span.value {
  display: none;
}

.dropdown dd ul li a {
  padding  : 5px;
  display  : block;
  font-size: 18px;
}

.dropdown dd ul li a:hover {
  background-color: #fff;
}

.dropdown img.flag {
  border        : none;
  vertical-align: middle;
  margin-left   : 10px;
}

.flagvisibility {
  display: none;
}

.bg-orange {
  background: #ff4500 !important;
  color     : #fff !important;
}

.th .hd_feat {
  border: none !important;
}

.modal-backdrop.show {
  display: none;
}

.modal-title {
  font-size  : 30px;
  font-weight: 600;
  color      : #ff4500;
}

.drag-image {
  border          : 4px dashed #ff4500;
  width           : 100%;
  padding         : 10px 0px;
  border-radius   : 5px;
  font-weight     : 400;
  display         : flex;
  align-items     : center;
  justify-content : center;
  flex-direction  : column;
  background-color: #fff;
}

.drag-image.active {
  border: 2px solid #fff;
}

.drag-image .icon {
  font-size: 30px;
  color    : #000;
}

.drag-image h6 {
  font-size  : 20px;
  font-weight: 300;
  color      : #000;
}

.drag-image span {
  font-size  : 14px;
  font-weight: 300;
  color      : #000;
  margin     : 10px 0 15px 0;
}

/* .drag-image input{
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 300;
  border: none;
  outline: none;
  background:#ff4500;
  border: 2px solid #ff4500;
  color: #ffff;
  border-radius: 20px;
  cursor: pointer;
  transition:all 0.5s;
} */
/* .drag-image button:hover{
   border: 2px solid #ff4500;
   background: #fff;
} */
.drag-image img {
  height       : 100%;
  width        : 100%;
  object-fit   : cover;
  border-radius: 5px;
}

/* ::-webkit-file-upload-button {
  padding      : 10px 25px;
  font-size    : 14px;
  font-weight  : 300;
  border       : none;
  outline      : none;
  background   : #ff4500;
  border       : 2px solid #ff4500;
  color        : #ffff;
  border-radius: 20px;
  cursor       : pointer;
  transition   : all 0.5s;
} */
.drag-image input[type="file"]::file-selector-button {
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 300;
  border: none;
  outline: none;
  background-color: #ff4500;
  border: 2px solid #ff4500;
  color: #ffff;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.5s;
}

.drag-image input[type="file"]::-ms-browse {
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 300;
  border: none;
  outline: none;
  background-color: #ff4500;
  border: 2px solid #ff4500;
  color: #ffff;
  border-radius: 20px;
  cursor: pointer;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}


.drag-image input[type="file"] {
  -webkit-file-upload-button: "Choose file";
  -moz-file-upload-button: "Choose file";
  -ms-file-upload-button: "Choose file";
}

#existing_num p,
#new_num p {
  font-size     : 18px;
  text-transform: uppercase;
}

.fifteen-3 span {
  font-size      : 16px;
  color          : #1a75bb;
  text-decoration: underline;
  letter-spacing : 1px;
  cursor         : pointer;
}

.main .step.third .code .resend-code2,
.main .step.ninth .code .resend-code2,
.main .step.second-step .code .resend-code2,
.main .step.fifth .code .resend-code2 {
  font-size      : 14px;
  font-weight    : 600;
  color          : #222;
  margin-left    : 20px;
  padding-bottom : 5px;
  border-bottom  : 3px solid #ff4500;
  text-decoration: none;
}

.other_main_carriers .action input:checked+span {
  font-size: 18px !important;
}

.other_main_carriers::-webkit-scrollbar {
  width: 10px;
}

.other_main_carriers::-webkit-scrollbar-thumb {
  background-color: #ff4500;
  border-radius   : 10px;
}

.code_verification .cat label input+span,
.three_main_carriers .cat label input+span {
  font-size: 18px !important;
}

.forth .action input:checked+span,
.eight .action input:checked+span,
.ninth .action input:checked+span {
  box-shadow      : 0px 0px 10px 2px #ff450085;
  background-color: #fff !important;
  border-color    : #ff4500;
}

.three_main_carriers .action input:checked+span:after,
.two_main_countries .action input:checked+span:after {
  content   : none;
  box-shadow: 2px 2px 10px 2px #00000066;
}

.code_verification .action input:checked+span:after {
  content   : none;
  box-shadow: 2px 2px 10px 2px #00000066;
}

.other_main_carriers .action input:checked+span:after,
.other_main_countries .action input:checked+span:after {
  content   : none;
  box-shadow: 2px 2px 10px 2px #00000066;
}

.wizard>.content>.body .cat label {
  display: block !important;
}

.action input:checked+span {
  padding         : 10px 0px;
  position        : relative;
  display         : flex;
  align-items     : center;
  font-size       : 20px;
  font-weight     : 400;
  color           : #8b8b8b;
  text-decoration : none;
  text-transform  : capitalize;
  background-color: #e5e5e5;
  border          : 1px solid #dbdbd9;
  border-radius   : 30px;
  margin          : 0px 10px;
  transition      : all 0.3s;
  justify-content : center;
}

#example-advanced-form>div>div.code_verification>div>div>label {
  display: block !important;
}

.icon-verify {
  padding-right: 7px;
  height       : auto;
}

.stepsnavigation {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

.stepsnavigation li.navigationItem.accessable {
  width          : 100%;
  height         : 8px;
  background     : #9e9e9e;
  list-style-type: none;
  cursor         : pointer;
  transition     : .3s background;
}

.stepsnavigation li.navigationItem.accessable:hover {
  background: #818181;
}

.stepsnavigation li.navigationItem.active {
  width          : 100%;
  height         : 8px;
  background     : #1a75bb;
  list-style-type: none;
  cursor         : pointer;
  transition     : .3s background;
}

.stepsnavigation li.navigationItem.active:hover {
  width     : 100%;
  height    : 8px;
  background: #156098;
}

.stepsnavigation li.navigationItem {
  width          : 100%;
  height         : 8px;
  background     : #fffc;
  list-style-type: none;
  transition     : .3s width;
}

.react-tel-input .form-control.default {
  width       : 360px !important;
  outline     : none !important;
  height      : 65px !important;
  font-size   : 24px !important;
  padding-left: 65px !important;
}

.current_working_number .react-tel-input .form-control.default {
  width: 100% !important;
}
.row1-col2 input.form-control.default {
  width: 100% !important;
}
.row1-col2 .form-control.sp-heading {
  padding: 20px 15px;
}
.react-tel-input .country-list {
  position: fixed !important;
}

.react-tel-input .selected-flag .flag {
  transform: scale(1.5);
}

/* .react-tel-input .selected-flag .arrow {
  transform: scale(1.5);
} */
.react-tel-input .selected-flag {
  width  : 55px !important;
  height : 100% !important;
  padding: 0 0 0 12px !important;
}

.schedule-field {
  width: 35%;
}

.step.first-step input[type="radio"] {
  cursor: pointer;
}

.three_main_carriers .cat.action label {
  width: 100%;
}

.action.carrier input:checked+span {
  box-shadow      : 0px 0px 10px 2px #ff450085;
  background-color: #fff !important;
  border-color    : #ff4500;
}

.action.carrier label img {
  margin-right: 10px;
}

.other_main_carriers .cat label {
  width: 100%;
}

.search_carriers {
  height: 60px;
}

.wrapper_search_carriers {
  border-radius: 0px;
}

.wrapper_search_carriers {
  border-radius: 0px;
}

#account-number {
  font-size     : 40px;
  padding       : 10px 25px;
  height        : auto;
  letter-spacing: 10px;
}

.step .order-field.account_number input {
  width        : 100%;
  border       : 0px;
  background   : #fff;
  border-radius: 0px;
  box-shadow   : none;
  font-size    : 18px;
  padding      : 10px 10px;
  height       : 50px;
  outline      : none;
}

.step .order-field.account_number input::placeholder {
  color: #b1b1b1;
}

.three_main_phone_options {
  display        : flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.three_main_phone_options .cat.action {
  align-items: center;
  margin-bottom: 0px;
  width: 110px;
}

.three_main_phone_options .action span {
  margin-bottom: 0px;
  align-items: center;
  width    : 114px !important;
  margin   : 0px !important;
  font-size: 16px !important;
  padding  : 10px !important;
}

.three_main_phone_options .action span .number_options {
  margin-right: 5px;
}

.signupbutton_content .cat.action label {
  width: 100%;
}

.signupbutton_content .cat.action label {
  width: 100%;
}

.signupbutton_content .cat.action label span img {
  margin-right: 10px;
}

.email-signup .cat.action label {
  width: 65%;
}

.email-signup .OR {
  font-size  : 20px;
  color      : #1a75bb;
  font-weight: bold;
  position   : relative;
}

.email-signup .OR:after {
  content   : "";
  position  : absolute;
  top       : 50%;
  right     : 55%;
  width     : 100px;
  height    : 1px;
  background: #ff4500;
}

.email-signup .OR:before {
  content   : "";
  position  : absolute;
  top       : 50%;
  left      : 55%;
  width     : 100px;
  height    : 1px;
  background: #ff4500;
}

.main .step .code .resend-code2 {
  font-size      : 14px;
  font-weight    : 600;
  color          : #222;
  margin-left    : 20px;
  padding-bottom : 5px;
  border-bottom  : 3px solid #ff4500;
  text-decoration: none;
}

.signup .cat label input+span {
  padding         : 12px 0px;
  position        : relative;
  display         : flex;
  align-items     : center;
  font-size       : 24px;
  font-weight     : 400;
  color           : #8b8b8b;
  text-decoration : none;
  text-transform  : capitalize;
  background-color: #fff;
  border          : 1px solid #dbdbd9;
  border-radius   : 30px;
  margin          : 0px 10px;
  transition      : all 0.3s;
  justify-content : center;
}

.signup .cat label input:checked+span {
  padding         : 12px 0px;
  position        : relative;
  display         : flex;
  align-items     : center;
  font-size       : 24px;
  font-weight     : 400;
  color           : #8b8b8b;
  text-decoration : none;
  text-transform  : capitalize;
  background-color: #e5e5e5;
  border          : 1px solid #dbdbd9;
  border-radius   : 30px;
  margin          : 0px 10px;
  transition      : all 0.3s;
  justify-content : center;
}

.area-code-number {
  border-top-right-radius   : 0;
  border-bottom-right-radius: 0;
  border                    : 0px;
  height                    : 55px;
}

.country-select-new-number {
  color          : #6c757d;
  height         : 55px;
  width          : 100%;
  display        : flex;
  align-items    : center;
  padding-left   : 12px;
  border         : 0px;
  background     : #fff url('../img/down-arrow.png') no-repeat scroll 98% center;
  text-decoration: none !important;
  outline        : none !important;
  font-size      : 24px;
}

.dropdown-menu.visible-solid {
  z-index : 9999;
  position: fixed;
  width   : 300px;
  display : block;
}

.cat.action label {
  width: 100%;
}

.cat.action label img {
  margin-right: 5px;
}

.card .select_pkg {
  background-color: #f7f7f7 !important;
  box-shadow      : none !important;
}

.checked.select_pkg {
  /* .card .btn-check:checked+.select_pkg, .checked.select_pkg { */
  color           : #fff !important;
  background-color: #1a75bb !important;
  border-color    : #1a75bb !important;
  box-shadow      : none !important;
}

h4.my-0.fw-normal {
  font-size: 17.5px;
}

.plans_card {
  z-index: 0 !important;
}

.plans_card a {
  text-decoration: none !important;
  border         : none !important;
  cursor         : pointer;
  color          : #1a75bb;
  text-transform : uppercase;
  font-size      : 14px;
}

.plans_card .btn.select_pkg {
  text-decoration           : none !important;
  border                    : none !important;
  text-transform            : uppercase;
  height                    : 45px;
  display                   : flex;
  align-items               : center;
  justify-content           : center;
  border-top-right-radius   : 0px;
  border-top-left-radius    : 0px;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius : 0.5rem;
  font-size                 : 18px;
  margin                    : 0;
}

.step input[type="radio"],
.step input[type="checkbox"] {
  cursor: pointer;
}

.file-drag-icon {
  max-width: 80px;
}

.order-field input {
  width        : 100%;
  border       : 0px;
  border-bottom: 2px solid #ccc;
  background   : #fff;
  border-radius: 0px;
  box-shadow   : none;
  font-size    : 18px;
  padding      : 10px 10px;
  height       : 50px;
  outline      : none;
}

.bannerAddOn {
  border-radius: 30px 30px 0px 0px;
  width        : 100%;
  margin-bottom: 25px;
  display      : block !important;
  object-fit   : cover;
  position     : absolute;
  top          : 0px;
  left         : 0px;
}

.spacer-addon-banner {
  height: 200px;
}

.radio-group .radio.selected,
.radio-groupAddons .radio.selected {
  box-shadow    : 0px 0px 20px 0px #ff450085;
  -moz-filter   : grayscale(0%);
  -o-filter     : grayscale(0%);
  -ms-filter    : grayscale(0%);
  filter        : grayscale(0%);
  border        : 2px solid #ff4500;
  transition    : .3s;
}

.radio-group .radio,
.radio-groupAddons .radio {
  display       : inline-block;
  border-radius : 0;
  border        : 2px solid #fff0;
  box-sizing    : border-box;
  cursor        : pointer;
  color         : #000;
  font-weight   : 500;
  -moz-filter   : grayscale(100%);
  -o-filter     : grayscale(100%);
  -ms-filter    : grayscale(100%);
  filter        : grayscale(100%);
}

.radio-group .card-block,
.radio-groupAddons .card-block {
  box-shadow      : 0px 0px 20px 0px transparent;
  width           : 200px;
  border          : 2px solid lightgrey;
  border-radius   : 20px !important;
  background-color: #FAFAFA;
}

.terms_condition {
  height    : 350px;
  overflow-y: scroll;
  background: #fff;
  font-size : 20px;
  padding   : 20px;
}

.signature-box {
  background      : #fff;
  border          : 4px dashed #ff4500;
  border-radius   : 5px;
  padding         : 10px 0px;
  /* height       : 300px;
  width           : 80%; */
}

.pin-field.complete[disabled] {
  background: rgba(106, 176, 76, .1);
  cursor    : not-allowed;
  opacity   : .5;
}

.pin-field.complete {
  background  : rgba(106, 176, 76, .1);
  border-color: #6ab04c;
  color       : #6ab04c;
}

.pin-field.error {
  background  : rgba(183, 12, 12, 0.185);
  border-color: rgba(183, 12, 12);
  color       : rgba(183, 12, 12);
}

.order_summary_pricing * {
  font-size  : 22px;
  padding    : 5px 0px;
  font-weight: 500;
}

.order_summary_pricing td small {
  font-size     : 12px;
  vertical-align: middle;
}

.order-summary-dashboard-btn {
  min-width       : 160px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  padding         : 10px 20px;
  font-size       : 18px;
  font-weight     : 700;
  color           : #fff;
  text-align      : center;
  text-decoration : none;
  text-transform  : uppercase;
  border          : 0;
  border-radius   : 30px;
  background-color: rgb(255, 69, 0);
  box-shadow      : 0px 15px 35px 0px rgb(255 66 0 / 32%);
  box-sizing      : border-box;
  /* margin-right : 30px; */
  cursor          : pointer;
  transition      : all 0.3s;
}

.order-summary-dashboard-btn:hover {
  background-color: rgb(255, 30, 0);
}

.custom-border-ene {
  border-right: 1px solid rgb(157, 165, 177) !important;
}

.orange {
  color: rgb(255, 69, 0);
}

.changePlan {
  position  : absolute;
  top       : -20px;
  left      : -20px;
  background: #ff4500;
  color     : #fff;
  padding   : 10px 20px;
  box-shadow: 4px 5px 20px #00000091;
  cursor    : pointer;
}

.changePlan a {
  text-decoration: none;
  color          : #fff;
}

.changePlan i {
  color       : #fff;
  font-size   : 20px;
  margin-right: 10px;
}

.audioToggle {
  position                 : fixed;
  top                      : 150px;
  right                    : 0px;
  width                    : 50px;
  height                   : 50px;
  background               : #1a75bb;
  color                    : #fff;
  border-top-left-radius   : 20px;
  border-bottom-left-radius: 20px;
  display                  : flex;
  justify-content          : center;
  align-items              : center;
  font-size                : 25px;
  cursor                   : pointer;
  transition               : .3s all;
}

.audioToggle.disabled {
  background: #ff4500;
}

.clearSignature {
  background   : #ff4500;
  border-radius: 30px;
  border       : none;
  color        : #fff;
  padding      : 10px 20px;
}

.checkSignature {
  background   : #1a75bb;
  border-radius: 30px;
  border       : none;
  color        : #fff;
  padding      : 10px 20px;
  text-transform: uppercase;
}

.signatureConfirmed {
  max-height : 100px;
  /* margin-left: 50%; */
  transform  : translate(0%, 0%)
}

.sidebar-toggle {
  cursor                       : pointer;
  background                   : #1a75bb;
  color                        : #fff;
  /* opacity                   : 0.6; */
  transition                   : .3s all;
  width                        : 50px;
  height                       : 80px;
  border-top-right-radius      : 100%;
  border-bottom-right-radius   : 100%;
  position                     : fixed;
  top                          : 50%;
  left                         : 0px;
  display                      : flex;
  align-items                  : center;
  justify-content              : center;
}

.sidebar-toggle.active {
  opacity   : 1;
  left      : 300px;
  background: #fff;
  color     : #1a75bb;
}

.sidebar-toggle:hover {
  opacity: 1;
}

.public-sidebar {
  overflow: hidden;
  position: fixed;
  width: 300px;
  max-height: calc(100vh - 88px);
  background: #fff!important;
  color: #696969;
  padding: 20px;
  z-index: 1;
}

.public-sidebar.collapsed {
  width  : 0px;
  padding: 0px;
}

.text-primary-c {
  color: #1a75bb !important;
}

.sidebarData {
  padding-bottom: 20px;
  overflow-y: auto;
  height: calc(100vh - 160px);
}
.sidebarData::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebarData {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width   : none;
  /* Firefox */
}

.sidebarData a {
  color          : #ff4500;
  text-decoration: none;
}

/* .generic-country-select>div>div{
  max-height: 56px;
}
.generic-country-select>div{
  max-height: 56px;
}
.generic-country-select #react-select-7-listbox{
  z-index: 999;
  max-height: 500px !important;
} */
.elaboration {
  position: relative;
}

.elaboration::before {
  height    : 0px;
  opacity   : 0;
  content   : "";
  position  : absolute;
  z-index   : 9999;
  background: #fff;
  transition: .3s opacity;
  width     : 10px;
  transition: .3s opacity;
  transform : rotate(-45deg);
}

.elaboration::after {
  height       : 0px;
  padding      : 0px;
  opacity      : 0;
  content      : attr(data-title);
  position     : absolute;
  z-index      : 9999;
  background   : #fff;
  color        : #1a75bb;
  border-radius: 5px;
  transition   : .3s opacity, height;
  text-align   : center;
  font-weight  : 600;
  box-shadow   : 12px 9px 11px 0px #00000059;
  overflow     : hidden;
}

.elaboration:hover::before {
  height: 10px;
}

.elaboration:hover::after {
  padding: 10px 20px;
  height : auto;
}

.elaboration:hover::after {
  opacity: 1;
}

.elaboration.top::after {
  bottom       : 100%;
  margin-bottom: 10px;
  left         : 50%;
  transform    : translateX(-50%);
}

.elaboration.bottom::after {
  top       : 100%;
  margin-top: 10px;
  left      : 50%;
  transform : translateX(-50%);
}

.elaboration.left::after {
  right     : 150%;
  box-shadow: -12px 9px 11px 0px #00000059;
}

.elaboration.right::after {
  left: 150%;
}

.elaboration:hover::before {
  opacity: 1
}

.elaboration.top::before {
  bottom   : 180%;
  left     : 50%;
  transform: translateX(-50%) rotate(-45deg);
}

.elaboration.bottom::before {
  top      : 180%;
  left     : 50%;
  transform: translateX(-50%) rotate(-45deg);
}

.elaboration.left::before {
  right: 138%
}

.elaboration.right::before {
  left: 138%;
}

.color-strange {
  color: rgb(25 57 84);
}

.w-80 {
  width: 80%;
}

img.center-check {
  animation   : pulse 1s infinite ease-in-out alternate;
  display     : block;
  margin-left : auto;
  margin-right: auto;
  /* position : relative;
  top         : 120px;
  margin-top  : -100px; */
}

@keyframes pulse {
  from {
    transform: scale(0.1s);
  }

  to {
    transform: scale(1.2);
  }
}

.password-validations .wrapper {
  display    : inline-block;
  align-items: center;
}

.password-validations img {
  max-width: 20px;
}

.orderDropdown {
  width              : 90%;
  height             : 55px;
  font-size          : 24px;
  font-weight        : 400;
  color              : #8b8b8b !important;
  padding-left       : 20px;
  background-color   : #fff !important;
  border             : 1px solid #dbdbd9;
  border-radius      : 0;
  box-sizing         : border-box;
  outline            : 0;
  box-shadow         : none;
  margin-bottom      : 0;
  -webkit-appearance : none;
  -moz-appearance    : none;
  background         : url('../img/icon-dd-arrow.png') no-repeat;
  background-position: center right 20px;
}

.numberRemove {
  position: absolute;
  background: crimson;
  border-radius: 100%;
  width: 25px !important;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  right: 0px;
  top: 45%;
  transform: translateY(-50%);
}
.add_new_number_div .position-relative {
  width: 100%;
  margin: 0 !important;
  padding-right: 35px;
}

.tipWrapper {
  width          : 100%;
  height         : 100%;
  position       : fixed;
  top            : 0;
  left           : 0;
  background     : rgba(0, 0, 0, 0.3);
  display        : flex;
  align-items    : center;
  justify-content: center;
  animation      : opacityAnimate 0.5s;
  transition     : .5s all;
  z-index        : 9999;
  border-radius: 30px;
}

.tipWrapper .tipModule {
  padding       : 40px;
  background    : #fff;
  position      : relative;
  width         : 450px !important;
  height        : auto;
  padding-top   : 40px;
  text-align    : center;
  animation     : slide-left 0.5s;
}

.tipWrapper.planDetails .tipModule {
  z-index: 9999999999999999999;
  width  : 500px;
}

.tipWrapper.addCarrier .tipModule {
  width: 500px;
}

.tipWrapper .tipModule .tipClose {
  position: absolute;
  top     : 0px;
  right   : 0px;
  z-index : 999;
  cursor  : pointer;
  padding : 12px 20px;
}

@keyframes opacityAnimate {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.tnc_link {
  text-decoration: none;
  color          : #1a75bb;
}

.green {
  color: #4bae4f;
}

.blue {
  color: #1a75bb;
}

.main-toggle {
  /* padding   : 5px 10px; */
  background: #fff;
  color     : #000;
  font-size : 18px;
  display   : flex;
  height    : 55px;
}

.main-toggle input {
  position: absolute;
  opacity : 0;
}

.main-toggle label:hover {
  background: #e3e3e3
}

.main-toggle label {
  padding     : 15px 15px;
  background  : #fff;
  width       : 100%;
  border-left : 1px solid #e3e3e347;
  border-right: 1px solid #e3e3e347;
  text-align  : center;
  cursor      : pointer;
  transition  : .3s all;
}

.main-toggle input[type=radio]:checked+label {
  background: #ff4500;
  color     : #fff;
  box-shadow: 1px 1px 16px 2px #0000003b;
  z-index   : 0;
}

.pmp-field {
  border-radius: 0px;
  height       : 56px;
  display      : flex;
  align-items  : center;
  font-size    : 24px;
  color        : #3d3d3d;
  border       : 0px !important;
  outline      : none !important;
  width        : 100%;
  padding-left : 15px;
}

.AntroVectra {
  font-family: 'Antro Vectra';
  font-size  : 65px;
}

.ArtySignature {
  font-family  : 'Arty Signature';
  font-size    : 145px;
  margin-top   : -50px !important;
  margin-bottom: 50px !important;
  line-height: 1;
}

.Creattion {
  font-family: 'Creattion';
  font-size  : 70px;
}

.Southam {
  font-family: 'Southam';
  font-size  : 70px;
}

.signatureConfirmed.topAdjustment {
  margin-top: 0px;
  /* width     : 400px; */
}

.random_number .feature {
  width          : 30px;
  height         : 30px;
  border-radius  : 100%;
  display        : block;
  border         : 1px solid #ff4500;
  display        : flex;
  justify-content: center;
  align-items    : center;
  margin         : 0px 5px;
}

.random_number .feature img {
  max-height: 20px;
}

.grey {
  color: #8c8c8c;
}

.changeLink {
  cursor          : pointer;
  color           : #fff;
  display         : flex;
  align-items     : center;
  justify-content : center;
  /* padding      : 10px 15px; */
  border-radius   : 15px;
  width           : 40px;
  height          : 30px;
  /* margin       : 0px auto 20px auto; */
}

.visa-image {
  max-width: 100px;
}

.sidebarData p {
  animation: opacityAnimate 0.5s;
}

.navbarToggler {
  display: none;
}

.sigCanvas {
  width : 100%;
  height: 250px;
}

@media(max-width: 767px) {
  .navbarToggler {
    display: block;
  }

  .navbarToggler img {
    width: 25px;
  }

  header .nav.nav-pills {
    opacity   : 0;
    visibility: hidden;
    display   : block;
    position  : fixed;
    top       : 64px;
    left      : 0;
    right     : 0;
    width     : 100%;
    padding   : 10px 15px 15px 15px !important;
    background: #fff;
    transition: all 0.2s;
    z-index   : 9;
  }

  header .nav.nav-pills a.nav-link {
    border-radius: 5px;
    font-size    : 16px;
  }

  header .nav.nav-pills.mobile-show {
    opacity   : 1 !important;
    visibility: visible !important;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1);
}

.QA_content .radio {
  padding: 5px !important
}

img.addon-pkg-image {
  padding: 10px 0px;
  width  : 70px;
}

.card-addons {
  --background         : #fff;
  --background-checkbox: #ff4500;
  --background-image   : #fff, rgba(0, 107, 175, 0.2);
  --text-color         : #666;
  --text-headline      : #000;
  --card-shadow        : #ff4500;
  --card-height        : auto;
  --card-width         : 190px;
  --card-radius        : 12px;
  --header-height      : auto;
  --blend-mode         : overlay;
  --transition         : 0.15s;
  -webkit-user-select  : none;
  user-select          : none;
  text-align           : center;
  border-radius        : 12px;
  box-shadow           : -3px 5px 15px 3px #00000045;
}

.card__body {
  display           : grid;
  grid-auto-rows    : calc(var(--card-height) - var(--header-height)) auto;
  background        : var(--background);
  height            : var(--card-height);
  width             : var(--card-width);
  border-radius     : var(--card-radius);
  overflow          : hidden;
  position          : relative;
  cursor            : pointer;
  box-shadow        : var(--shadow, 0 4px 4px 0 rgba(0, 0, 0, 0.02));
  transition        : transform var(--transition), box-shadow var(--transition);
  transform         : scale(var(--scale, 1)) translateZ(0);
}

.card__body:active {
  --scale: 0.96;
}

.card__body-cover-image {
  width            : var(--c-width);
  height           : var(--c-height);
  object-fit       : cover;
  border-radius    : var(--c-border);
  filter           : var(--filter-bg, grayscale(1));
  -webkit-clip-path: polygon(0% 0%, 100% 0%, var(--x-y1, 100% 90%), var(--x-y2, 67% 83%), var(--x-y3, 33% 90%), var(--x-y4, 0% 85%));
  clip-path        : polygon(0% 0%, 100% 0%, var(--x-y1, 100% 90%), var(--x-y2, 67% 83%), var(--x-y3, 33% 90%), var(--x-y4, 0% 85%));
}

.card__body-header {
  height    : var(--header-height);
  background: var(--background);
  padding   : 0 10px 10px 10px;
}

.card__body-header-title {
  color         : var(--text-headline);
  font-weight   : 700;
  margin-bottom : 8px;
  font-size     : 16px;
  text-transform: uppercase;
}

.card__body-header-subtitle {
  color      : var(--text-color);
  font-weight: 500;
  font-size  : 16px;
}

.card__input {
  position          : absolute;
  display           : block;
  outline           : none;
  border            : none;
  background        : none;
  padding           : 0;
  margin            : 0;
  -webkit-appearance: none;
}

.card__input:checked~.card__body {
  --shadow: 0 0 0 5px var(--card-shadow);
}

.card__input:checked~.card__body .card__body-cover-checkbox {
  --check-bg     : var(--background-checkbox);
  --check-border : #fff;
  --check-scale  : 1;
  --check-opacity: 1;
}

.card__input:checked~.card__body .card__body-cover-checkbox--svg {
  --stroke-color     : #fff;
  --stroke-dashoffset: 0;
}

.card__input:checked~.card__body .card__body-cover:after {
  --opacity-bg: 0;
}

.card__input:checked~.card__body .card__body-cover-image {
  --filter-bg: grayscale(0);
}

.card__input:disabled~.card__body {
  cursor : not-allowed;
  opacity: 0.5;
}

.card__input:disabled~.card__body:active {
  --scale: 1;
}

.card__body-cover-checkbox {
  background       : var(--check-bg, var(--background-checkbox));
  border           : 2px solid var(--check-border, #fff);
  position         : absolute;
  right            : 10px;
  top              : 10px;
  z-index          : 1;
  width            : 28px;
  height           : 28px;
  border-radius    : 50%;
  opacity          : var(--check-opacity, 0);
  transition       : transform var(--transition), opacity calc(var(--transition) * 1.2) linear;
  transform        : scale(var(--check-scale, 0));
}

.card__body-cover-checkbox--svg {
  width             : 13px;
  height            : 11px;
  display           : inline-block;
  vertical-align    : top;
  fill              : none;
  margin            : 7px 0 0 0px;
  stroke            : var(--stroke-color, #fff);
  stroke-width      : 3;
  stroke-linecap    : round;
  stroke-linejoin   : round;
  stroke-dasharray  : 16px;
  stroke-dashoffset : var(--stroke-dashoffset, 16px);
  transition        : stroke-dashoffset 0.4s ease var(--transition);
}

.select-num-image {
  width  : 40px;
  padding: 10px 0px;
}

.choose_number {
  position: relative;
}

.password-validation-wrapper {
  display              : grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.main-toggle input[type=radio]:checked+label img {
  filter: brightness(0) invert(1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin            : 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.payment-form {
  position: relative;
  z-index : 99;
}

.provide_later_button {
  padding         : 10px 20px;
  font-size       : 18px;
  font-weight     : 700;
  margin-left     : 180px;
  margin-bottom   : -88px;
  margin-top      : 40px;
  display         : block;
  color           : #1a75bb;
  text-align      : right;
  text-decoration : none;
  text-transform  : uppercase;
  border          : 0;
  border-radius   : 30px;
  /* background-color: #9e9e9e; */
  box-sizing      : border-box;
  cursor          : pointer;
  transition      : all 0.3s;
}

.card__body-cover-checkbox2 {
  width        : 28px;
  height       : 28px;
  border       : 2px solid #ccc;
  position     : absolute;
  top          : 10px;
  right        : 9px;
  border-radius: 20px;
}

.pwd-container img {
  cursor  : pointer;
  position: absolute;
  width   : 30px;
  right   : 15px;
  top     : 40px;
}

.three_main_addon_options {
  display        : flex;
  justify-content: space-between;
}

.three_main_addon_options .cat label input+span {
  width: 100%;
}

.number_row {
  background   : #fff;
  box-shadow   : 0px 0px 10px #ccc;
  border-radius: 10px;
  padding      : 0px 5px;
  margin       : 15px 0px;
}

.changeLinknumber {
  cursor          : pointer;
  display         : flex;
  align-items     : center;
  justify-content : center;
  /* padding      : 10px 15px; */
  /* margin       : 0px auto 20px auto; */
}

.changeLinknumber.elaboration.left::before {
  right: 112%;
}

.changeLinknumber.elaboration.left::after {
  right     : 110%;
  box-shadow: -12px 9px 11px 0px #00000059;
}

.continue_form_buttons2 a {
  background-color: #1a75bb;
  border          : 2px solid #1a75bb;
  font-weight     : 700;
  text-transform  : uppercase;
  color           : #fff;
  text-decoration : none;
  font-size       : 22px;
  padding         : 15px 30px;
  border-radius   : 10px;
}

.continue_form_buttons2 a:hover {
  background-color: #fff;
  color           : #1a75bb;
  border          : 2px solid;
}

.continue_form_buttons1 a {
  background-color: transparent;
  border          : 2px solid gray;
  font-weight     : 700;
  text-transform  : uppercase;
  color           : GRAY;
  text-decoration : none;
  font-size       : 22px;
  padding         : 15px 30px;
  border-radius   : 10px;
}

.continue_form_buttons1 a:hover {
  background-color: #fff;
  color           : #ff4500;
  border          : 2px solid;
}

.continue_form_text h4 {
  font-size  : 30px;
  font-weight: 600;
  padding-top: 20px;
}

.continue_form_text p {
  font-size: 20px;
}

/* .continue_form_buttons1 .btn:focus, .continue_form_buttons2 .btn:focus{
  background-color: #fff !important;
  box-shadow: none !important;
} */
.resume_modal_wrapper {
  margin: 35px;
}

.sidebarData-popup {
  max-height: 300px;
  overflow-y: auto;
}

.sidebarData-popup::-webkit-scrollbar {
  width           : 10px;
  background-color: rgba(26, 117, 187, 0.2);
}

.sidebarData-popup::-webkit-scrollbar-thumb {
  background-color: #1a75bb;
  border-radius   : 10px;
}

.number_type .elaboration.top:before {
  bottom: 105%;
}

.micro {
  font-size      : 13px;
  text-transform : uppercase;
  /* padding-left: 25px; */
}

.summary_number_details {
  display    : flex;
  align-items: center;
}

.order_summary_left h4 {
  font-size: 18px;
}

.country-icon img {
  min-width: 30px;
  max-width: 30px;
  height   : auto;
}

.order_summary_right table tr td {
  vertical-align: middle;
}

.number_plans .col {
  z-index: 0 !important;
}

button.more_number_button {
  background   : #fff;
  color        : #333;
  border       : 0;
  margin       : 5px 0px;
  padding      : 10px 25px;
  box-shadow   : 0px 0px 10px #ccc;
  border-radius: 30px;
}

.more_number_button:hover {
  background-color: #fff !important;
  color           : #333 !important;
}

.more_number_button:focus {
  box-shadow      : 0px 0px 10px #ccc !important;
  background-color: #fff !important;
  color           : #333 !important;
}

.collapse img.addon-pkg-image {
  width: 50px !important;
  height: 70px;
}

.collapse .row:nth-child(1) .card__body-header {
  padding-top: 45px;
}

button.more_number_button {
  width: 100%;
}

button.more_number_button h3 {
  text-align: left;
}

.collapse h3 {
  margin    : 15px 0px;
  font-size : 22px;
  color     : #747171;
  text-align: center;
}

.more_plans_card .pricing-card-title {
  font-size: 30px;
  margin   : 0;
}

.btn-check:checked+.btn-outline-primary {
  background-color: #156098 !important;
  border-color    : #156098 !important;
  color           : #fff !important;
}

.btn-outline-primary:hover {
  background-color: #156098 !important;
  border-color    : #156098 !important;
  color           : #fff !important;
}

.btn-outline-primary {
  border-color           : #156098 !important;
  color                  : #156098 !important;
  border-top-left-radius : 0 !important;
  border-top-right-radius: 0 !important;
}

h3 span {
  background   : #a7a4a4;
  color        : #fff;
  padding      : 5px 11px;
  font-size    : 22px;
  margin-left  : 8px;
  border-radius: 30px;
}

a.proceed_collapse_button {
  color          : #156098;
  text-decoration: none;
  font-size      : 25px;
  padding        : 1px 35px;
  border-radius  : 40px;
  width          : auto;
  border         : 3px solid #156098;
  margin-top: 10px;
}

.proceed_collapse_button:hover {
  color: #fff;
}

.more_plans_card .card-header h4 {
  font-size: 15px;
}

.button_collapse {
  border: 3px solid #ff4500 !important;
}

img.collapse_arrow {
  height      : 30px;
  float       : right;
  margin-right: 20px;
}

.button_collapse .collapse_arrow {
  transform: rotate(180deg);
}

.number_count_icon {
  width: 34px;
}

.done-button {
  min-width       : 160px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  padding         : 10px 20px;
  font-size       : 18px;
  font-weight     : 700;
  color           : #fff;
  text-align      : center;
  text-decoration : none;
  text-transform  : uppercase;
  border          : 0;
  border-radius   : 30px;
  background-color: #4bae4f;
  box-sizing      : border-box;
  cursor          : pointer;
  transition      : all 0.3s;
}

.done-button:hover {
  background-color: #2d7c30;
}

.load-more-button {
  min-width       : 160px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  padding         : 10px 20px;
  font-size       : 18px;
  font-weight     : 700;
  color           : #fff;
  text-align      : center;
  text-decoration : none;
  text-transform  : uppercase;
  border          : 0;
  border-radius   : 30px;
  background-color: #1a75bb;
  box-sizing      : border-box;
  cursor          : pointer;
  transition      : all 0.3s;
}

.load-more-button:hover {
  background-color: #1a75bb;
}

.tinyLoaderWrapper {
  position       : absolute;
  top            : 0px;
  left           : 0px;
  width          : 100%;
  height         : 100%;
  background     : #e3e3e3;
  opacity        : 0.6;
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.tiny-loader {
  border           : 16px solid #f3f3f3;
  border-radius    : 50%;
  border-top       : 16px solid #1a75bb;
  width            : 120px;
  height           : 120px;
  /* Safari */
  animation        : spin 2s linear infinite;
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.step.plans_box {
  width: 100%;
}
@media only screen and (max-width: 1536px){
  .main{
  zoom: 0.8;
}

}
/* dont mess with this width this is a build fix */
.rccs,
.rccs__card {
  width: 100% !important;
}
#carrier_option_mobile.disabled{
  cursor: not-allowed !important;
}


.state .generic-country-selects .state-province-select{
  margin: -6px 0px 0px 0px!important;

}
.state .generic-country-selects .state-province-select-span span{
  margin-top: 0px!important;
}

.state .generic-country-selects .state-province-select-span div{
  margin-top: -5px;
}

  /* scrollbar css */


  /* * {
    scrollbar-width: auto;
    scrollbar-color: #ff4500 #ffffff;
  }

  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ff4500;
    border-radius: 10px;
    border: 3px solid #ffffff;
  } */
  .phone_number_summary{
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
