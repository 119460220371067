#message {
  color: #000;
}

#message p {
  font-size: 14px;
}

.valid:before {
  position: relative;
  left    : -5px;
  content : "✔";
}

.invalid {
  color: red;
}

.invalid:before {
  position: relative;
  left    : -5px;
  content : "✖";
}

div#message li {
  display        : inline-block;
  margin         : 0px 0px 0px 15px;
  list-style-type: none;
}

.mobile-image {
  width       : 120px;
  display     : block;
  margin-left : auto;
  margin-right: auto;
}

img.center-check {
  animation     : pulse 1s infinite ease-in-out alternate;
  display       : block;
  margin-left   : auto;
  margin-right  : auto;
  /* position   : relative;
      top       : 120px;
      margin-top: -100px; */
}

@keyframes pulse {
  from {
    transform: scale(0.1s);
  }

  to {
    transform: scale(0.8s);
  }
}

/* ///////////// */
.loader {
  position       : fixed;
  top            : 0;
  left           : 0;
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
  width          : 100vw;
  height         : 100vh;
  background     : #fff;
  z-index        : 999
}

.loader .loading-logo {
  width: 150px
}

.loader-main {
  position: relative;
  width   : 80px;
  height  : 50px
}

.loader-main div {
  position                 : absolute;
  top                      : 17px;
  width                    : 18px;
  height                   : 18px;
  border-radius            : 50%;
  background               : #ff4500;
  animation-timing-function: cubic-bezier(0, 1, 1, 0)
}

.loader-main div:first-child {
  left     : 8px;
  animation: .6s infinite circle1
}

.loader-main div:nth-child(2) {
  left     : 8px;
  animation: .6s infinite circle2
}

.loader-main div:nth-child(3) {
  left     : 32px;
  animation: .6s infinite circle2
}

.loader-main div:nth-child(4) {
  left     : 56px;
  animation: .6s infinite circle3
}


@keyframes circle1 {
  0% {
    transform: scale(0)
  }

  100% {
    transform: scale(1)
  }
}

@keyframes circle3 {
  0% {
    transform: scale(1)
  }

  100% {
    transform: scale(0)
  }
}

@keyframes circle2 {
  0% {
    transform: translate(0, 0)
  }

  100% {
    transform: translate(24px, 0)
  }
}

/* .coverage_body {
  background     : url("../img/happy-young-woman-showing-blank-screen-mobile-phone-hand-gesture-success-isolated-pink-background.jpg") no-repeat !important;
  background-size: cover !important;
  background: linear-gradient(45deg, #1b75bc, #b0cfed);
  height         : 91vh;
  display        : flex;
  align-items    : center;
  justify-content: center;
} */

.signup_body {
  background     : url("../img/wizard-bg.png") no-repeat !important;
  background-size: cover !important;
  height         : 91vh;
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.order_body {
  background     : url("../img/bg-image.jpg") no-repeat !important;
  background-size: cover !important;
  min-height: calc(100vh - 90px);
  display        : flex;
  align-items    : center;
  justify-content: center;
}
.order-plans-card {
  min-width: 1400px;
}
.login_continue i.fas {
  padding: 0px 0px 0px 8px;
}

.radio-toolbar {
  margin: 10px;
}

.radio-toolbar input[type="radio"] {
  opacity : 0;
  position: fixed;
  width   : 0;
}

.radio-toolbar label {
  display      : inline-block;
  padding      : 10px 20px;
  font-family  : sans-serif, Arial;
  font-size    : 16px;
  border-radius: 0px;
  border-bottom: 2px solid #1973b7;
}

.radio-toolbar input[type="radio"]:checked+label {
  background-color: #1a75bb;
  border-color    : #1a75bb;
  color           : #fff;
  font-size       : 16px;
  transition      : all 0.5s linear;
}

/* ///// */

.random_number label {
  display      : block;
  position: relative;
  margin-top   : 10px;
  text-align   : center;
  line-height  : 40px;
  padding      : 10px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.random_number label:hover {
  background: #c1c1c1;
  transition: .5s;
}

.random_number input[type=radio] {
  display: none;
}

/* .random_number input:checked+label:before {
      content: "✓ ";
      } */
.random_number input:checked+label:after {
  content          : '';
  position         : absolute;
  z-index          : 1;
  font-size        : 26px;
  height           : 20px;
  padding          : 18px;
  background-image : url("../img/eo_circle_green_checkmark.png");
  background-repeat: no-repeat;
  background-size  : contain;
  /* animation        : pulse 1s ease-in-out alternate; */
  margin           : -25px 0px;
  top           : 10px;
  right           : -7px;
}

.random_number input:checked+label {
  background-color: #e5e5e5;
  color           : #595959;
  transition      : .5s;
}

/* Payment Flip Card */
.payment-card.small-card {
  transform: scale(0.3);
  transform-origin: top left;
}
.payment-card {
  cursor: pointer;
  width: 400px;
  height: 251px;
  font-family: 'Source Code Pro', monospace;
  background-color: transparent;
  perspective: 1000px;
  margin: 0 auto;
}
.payment-card:hover .inner {
  transform: rotateY(180deg);
}

.payment-card .inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 1px 5px 6px 0px black;
  border-radius: 22px;
  /* overflow: hidden; */
}
/* .show-back .inner {
  transform: rotateY(180deg);
} */
.payment-card .front, 
.payment-card .back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 22px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.6s;
}
.payment-card .front .header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 15px 35px;
  border: 0;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}
.payment-card .front .header .chip{
  width: 65px;
}
.payment-card .front .header .chip img{
  max-width: 100%;
}
.payment-card .front .header .logo{
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-card .front .header .logo img{
  max-width: 100%;
  display: none;
}
.payment-card .front .body{
  background-color: transparent;
  padding: 20px 35px 0;
}
.payment-card .front .body .card-number{
  margin-bottom: 35px;
}
.payment-card .front .body .card-number .heading{
  font-size: 13px;
  color: #ffffff9c;
  line-height: 1;
  text-align: left;
  text-transform: lowercase;
  margin-bottom: 3px;
}
.payment-card .front .body .card-number .number{
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  text-align: left;
  text-transform: capitalize;
}
.payment-card .front .body .card-name .heading{
  font-size: 13px;
  color: #ffffff9c;
  line-height: 1;
  text-align: left;
  text-transform: lowercase;
  margin-bottom: 3px;
}
.payment-card .front .body .card-name .name{
  font-size: 20px;
  color: #fff;
  line-height: 1;
  text-align: left;
  text-transform: uppercase;
}
.payment-card .front .body .expiration .heading{
  font-size: 13px;
  color: #ffffff9c;
  line-height: 1;
  text-align: left;
  text-transform: lowercase;
  margin-bottom: 5px;
}

.payment-card .front .body .expiration .valid-thru{
  font-size: 10px;
  color: #ffffff9c;
  line-height: 1;
  padding-right: 15px;
  text-align: left;
  text-transform: uppercase;
}
.payment-card .front .body .expiration .date{
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  text-align: left;
  text-transform: uppercase;
}

.payment-card .back {
  transform: rotateY(180deg);
}
.payment-card .back .magnetic-strip{
  width: 100%;
  height: 41px;
  margin: 35px 0 25px;
  background-color: #000;
}
.payment-card .back .sign-area{
  width: 355px;
  height: 35px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: #F2F2F2;
  border-radius: 3px;
}
.payment-card .back .sign-area .sign-name{
  position: relative;
  width: 398px;
  height: 100%;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #47525d;
  line-height: 1;
  text-align: left;
  text-transform: capitalize;
  z-index: 1;
}
.payment-card .back .sign-area .sign-name::before,
.payment-card .back .sign-area .sign-name::after{
  content: "";
  position: absolute;
  left: 0;
  height: 5.6px;
  width: 100%;
  background-color: #D8D2DB;
  z-index: -1;
}
.payment-card .back .sign-area .sign-name::before{
  top: 8px;
}
.payment-card .back .sign-area .sign-name::after{
  bottom: 8px;
}
.payment-card .back .sign-area .cvc{
  min-width: 57px;
  height: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #C4C4C4;
  font-size: 16px;
  font-weight: 500;
  color: #47525d;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.payment-card .back .code-heading{
  font-size: 13px;
  color: #fffc;
  line-height: 1;
  text-transform: lowercase;
  margin: 6px 23px 0 0;
  text-align: right;
}
.payment-card .back .sm-strip{
  position: absolute;
  left: 25px;
  bottom: 45px;
  width: 200px;
  height: 7px;
  background-color: #EAEAEA;
}
.payment-card .back .md-strip{
  position: absolute;
  left: 25px;
  bottom: 30px;
  width: 225px;
  height: 7px;
  background-color: #EAEAEA;
}
/* Payment Flip Card */

/* Visa card css */
.visa-card .front,
.visa-card .back {
  background-color: #afb42b;
}
.visa-card .front .header {
  background-color: #d4e157;
}
.visa-card .front .header .logo .visa-logo {
  display: block;
}
/* Visa card css */

/* Mastercard css */
.master-card .front,
.master-card .back {
  background-color: #0288D1;
}
.master-card .front .header {
  background-color: #03A9F4;
}
.master-card .front .header .logo .mastercard-logo {
  display: block;
}
/* Mastercard css */

/* American Express Card css */
.american-express-card .front,
.american-express-card .back {
  background-color: #388e3c;
}
.american-express-card .front .header {
  background-color: #66bb6a;
}
.american-express-card .front .header .logo .american-express-logo {
  display: block;
}
/* American Express Card css */

/* Maestro card css */
.maestro-card .front,
.maestro-card .back {
  background-color: #f9a825;
}
.maestro-card .front .header {
  background-color: #ffeb3b;
}
.maestro-card .front .header .logo .maestro-logo {
  display: block;
}
/* Maestro card css */

/* JCB Card css */
.jcb-card .front,
.jcb-card .back {
  background-color: #d32f2f;
}
.jcb-card .front .header {
  background-color: #ef5350;
}
.jcb-card .front .header .logo .jcb-logo {
  display: block;
}
/* JCB Card css */

/* Union Pay Card css */
.unionpay-card .front,
.unionpay-card .back {
  background-color: #0097a7;
}
.unionpay-card .front .header {
  background-color: #26c6da;
}
.unionpay-card .front .header .logo .unionpay-logo {
  display: block;
}
/* Union Pay Card css */

/* Discover Card css */
.discover-card .front,
.discover-card .back {
  background-color: #7b1fa2;
}
.discover-card .front .header {
  background-color: #ab47bc;
}
.discover-card .front .header .logo .discover-logo {
  display: block;
}
/* Discover Card css */

/* Diners Club Card css */
.diners-club-card .front,
.diners-club-card .back {
  background-color: #ef6c00;
}
.diners-club-card .front .header {
  background-color: #ff9800;
}
.diners-club-card .front .header .logo .diners-club-logo {
  display: block;
}
/* Diners Club Card css */
