.order-addons-card-more{
  justify-content: center;
}
.order-plans-card-more{
  justify-content: center;
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
  .autocomplete-dropdown-container .suggestion-item span{
    font-size: 16px;
  }
  .order-field input{
    font-size: 18px;
    height: 30px;
    text-align: center;
  }
  .QA_content .radio{
    margin: 0px;
  }
  .order-addons-card-more{
    justify-content: start;
  }
  .react-tel-input .form-control {
    width: 100% !important;
}
  .order_body {
    background-position: right !important;
    
    /* min-height: calc(100vh - 72px); */
    height: 100%!important;
}
.add_new_number_div .position-relative {
  width: 100%;
  margin: 0 !important;
  padding-right: 0px;
}
  .order-plans-card-more{
    justify-content: start;
  }
  .credit-card {
    display: none;
  }
  .mt-25.container1 {
    display: block;
    text-align: center;
}
  .signupbutton_content .row-f {
    display: block;
  }

  .signupbutton_content .cat.action label {
    display      : inline-block;
    margin-bottom: 0.5em;
  }

  .email-signup .cat.action label {
    width: 100%;
  }
  .QA_content .radio input[type=radio]+.radio-label{
    font-size: 22px;
  }
  .audioToggle {
    top    : 80px !important;
    z-index: 1;
  }

  /* .signup_body,
  .coverage_body {
    height: 80vh !important;
  } */

  .elaboration::before,
  .elaboration::after {
    display: none;
  }

  .girl-phone {
    display: none;
  }

  /* .three_main_phone_options {
    display: block;
  } */

  .three_main_phone_options .cat label input+span {
    width : 100% !important;
    margin: 10px auto !important;
  }

  .main .step .add-number-btn {
    position: static !important;
    margin: auto;
    margin-top: 15px;
}

  .numberRemove {
    right: 3%;
    top: 22%;
  }

  .main .step .prev-step {
    position              : absolute;
    left                  : 0;
    top                   : 0;
    bottom                : 43px;
    width                 : 40px;
    height                : 40px;
    display               : flex;
    align-items           : center;
    justify-content       : center;
    background-color      : #51709c;
    text-decoration       : none;
    cursor                : pointer;
    border-top-left-radius: 25px;
  }

/* temporary */
.main .step .next-step {
  display: block;
}

  .prev-step img {
    transform: rotate(270deg);
  }

  .radio-group .card-block {
    width : 100% !important;
    margin: 10px 0px;
  }

  img.irc_mut.img-fluid {
    height: 60px;
    width : 60px;
  }

  .row-f {
    display: block !important;
  }

  .code_verification .cat.action {
    margin-bottom: 15px;
  }
  .three_main_carriers .cat.action {
    width: 100% !important;
  }
  .other_main_carriers .cat.action {
    width: 100% !important;
  }
  .code_verification .cat.action {
    margin: 10px 0px;
    width: 100%;
  }

  #existing_num p,
  #new_num p {
    font-size: 16px;
  }

  .generic-help-link {
    bottom: 85px;
  }

  .generic-help-link {
    position: static !important;
  }

  .changePlan {
    top: -70px;
    width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
}

  .spacer-addon-banner {
    height: 60px;
  }

  .random_number {
    font-size: 14px !important;
  }

  .sidebar-toggle {
    /* top       : 80%; */
    z-index   : 9999;
    translate : unset;
    transition: unset;
    width: 25px;
    height: 60px;
  }

  /* .sidebar {
    left       : 0 !important;
    margin-left: 0px !important;
    height     : 100vh;
    width      : 100vw;
    transition : .7s z-index;
  } */

  .main.slideRight {
    margin-left: unset !important;
  }

  .sidebar-toggle.active {
    z-index                   : 9999 !important;
    background                : #1a75bb;
    color                     : #fff;
    right                     : 0px;
    left                      : unset;
    border-top-left-radius   : 100%;
    border-bottom-left-radius: 100%;
    border-top-right-radius   : 0%;
    border-bottom-right-radius: 0%;
  }
  .checkmark-wrapper, .crossmark-wrapper, .circlemark-wrapper {
    width: 90%;
    top: -60px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
}
.crossmark-wrapper
{
  top: -60px;
}
.step-21 .crossmark-wrapper{
  top: -40px!important;
}
.step-21 .changePlan{
  top: -110px!important;
}

.plan-modal .modal-body {
  overflow-x: scroll;
}
.plan-modal .modal-body .table,.plan-modal .modal-body h3 {
  min-width: 600px;
}
.checkmark-wrapper .message, .crossmark-wrapper .message, .circlemark-wrapper .message {
  margin-right: 0px;
  width: 100%;
}
 .crossmark-wrapper .message {
height: auto;
  padding-right: 50px;
}
.crossmark.animateElement {
  right: 0px;
  top: 0px;
}
.public-sidebar {
  width: 100%;
  height: 100vh;
  z-index: 999;
  position: fixed !important;
  top: 0;
  left: 0;
}
  .main .step .lg-heading{
    margin-bottom: 10px !important;
  }
  .radio-group .pic{
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .main .step .footer{
    margin-top: 20px;
  }
  .main .step .sm-heading{
    font-size: 13px;
  }
  .main .step{
    width: 400px !important;
  }
  .choose_btw_payment .left[data-v-64500fee]{
    display: block;

  }
  .choose_btw_payment .only_text[data-v-64500fee]{
    font-size: 12px;
    padding-left: 8px;
  }


}
@media only screen and (max-width: 800px) and (min-width: 767px) {
  .main .step{
    width: 850px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .step.choose-plan {
    min-width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .selector-item_label .selected-check,
  .selector-item_label.checked .selected-check {
    top: 5px !important;
    right: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .generic-help-link {
    width: 100%;
    min-width: 100%;
}
  .payment-form{
    margin: 0 !important;
  }
  .modal-body {
    overflow-x: auto;
}
  .main.slideRight {
    margin-left: 0px !important;
}
.step.choose-plan {
  min-width: 100%;
}
.changePlan.is_change_plan {
  top: -137px;
}
.generic-country-selects div:last-child {
  max-height: 200px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.main {
  transform: scale(1);
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.step .code {
  flex-direction: column;
}
.step .code .resend-code{
  margin-top: 5px;
}


.state .generic-country-selects .state-province-select{
  margin: -4px 0px 0px 0px!important;
  

}
.state .generic-country-selects .state-province-select-span span{
  margin-top: 0px!important;
  margin-bottom: 5px;
}
.cat_options .react-multi-carousel-track{
  flex-direction: column!important;
}
.cat_options ul.react-multi-carousel-track li{
  width: 100%!important;
}
.new_plan_cards .category-icon-wrapper .category-icon {
  width: 50px;
  height: 50px;
  
}
.new_plan_cards .category-icon-wrapper{
  width: 80px;
  height: 80px;
}
.selector-item_label .selected-check, .selector-item_label.checked .selected-check{
  top: -10px !important;
  right: -16px !important;
}
}


@media only screen and (max-width: 480px) {
.three_main_phone_options .cat.action {
  width: 100px;
}


}
