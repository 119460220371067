.round-button[data-v-cbc6a1ce] {
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 0;
    border-radius: 30px;
    background-color: rgb(255, 69, 0);
    box-shadow: 0px 15px 35px 0px rgb(255 66 0 / 32%);
    box-sizing: border-box;
    /* margin-right: 30px; */
    cursor: pointer;
    transition: all 0.3s;
}
.round-button[disabled][data-v-cbc6a1ce] {
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 0;
    border-radius: 30px;
    background-color: rgb(255 69 0 / 50%);
    box-shadow: 0px 15px 35px 0px rgb(255 66 0 / 32%);
    box-sizing: border-box;
    /* margin-right: 30px; */
    cursor: pointer;
    transition: all 0.3s;
}
.round-button[data-v-cbc6a1ce]:hover {
    background-color: #51709c !important;
    transition: all 0.3s !important;
}
.round-button[data-size='small'][data-v-cbc6a1ce] {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 27px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    text-transform: capitalize;
    border: 0;
    border-radius: 50vh;
    background-color: rgb(255, 69, 0);
    box-shadow: 0px 15px 35px 0px rgb(255 66 0 / 32%);
    box-sizing: border-box;
    transition: all 0.3s;
    min-width: auto;
    font-size: 15px;
}
.round-button[data-size='small'][disabled][data-v-cbc6a1ce] {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 27px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    text-transform: capitalize;
    border: 0;
    border-radius: 50vh;
    background-color: rgb(255 69 0 / 50%);
    box-shadow: 0px 15px 35px 0px rgb(255 66 0 / 32%);
    box-sizing: border-box;
    transition: all 0.3s;
    min-width: auto;
    font-size: 15px;
}
