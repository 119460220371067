@media only screen and (max-width: 767px) and (min-width: 280px) {
  
  .credit-card {
    display: none;
  }
  .mt-25.container1 {
    display: block;
    text-align: center;
}
  .signupbutton_content .row-f {
    display: block;
  }

  .signupbutton_content .cat.action label {
    display      : inline-block;
    margin-bottom: 0.5em;
  }

  .email-signup .cat.action label {
    width: 100%;
  }
  .QA_content .radio input[type=radio]+.radio-label{
    font-size: 22px;
  }
  .audioToggle {
    top    : 80px !important;
    z-index: 1;
  }

  /* .signup_body,
  .coverage_body {
    height: 80vh !important;
  } */

  .elaboration::before,
  .elaboration::after {
    display: none;
  }

  .girl-phone {
    display: none;
  }

  /* .three_main_phone_options {
    display: block;
  } */

  .three_main_phone_options .cat label input+span {
    width : 100% !important;      
    margin: 10px auto !important;
  }

  .main .step .add-number-btn {
    position: static !important;
    margin  : auto;
  }

  .numberRemove {
    right: 10px;
    top  : 5%;
  }

  .main .step .prev-step {
    position              : absolute;
    left                  : 0;
    top                   : 0;
    bottom                : 43px;
    width                 : 40px;
    height                : 40px;
    display               : flex;
    align-items           : center;
    justify-content       : center;
    background-color      : #51709c;
    text-decoration       : none;
    cursor                : pointer;
    border-top-left-radius: 25px;
  }

  .main .step .next-step {
    display: none;
  }

  .prev-step img {
    transform: rotate(270deg);
  }

  .radio-group .card-block {
    width : 100% !important;
    margin: 10px 0px;
  }

  img.irc_mut.img-fluid {
    height: 60px;
    width : 60px;
  }

  .row-f {
    display: block !important;
  }

  .code_verification .cat.action {
    margin-bottom: 15px;
  }
  .three_main_carriers .cat.action {
    width: 100% !important;
  } 
  .other_main_carriers .cat.action {
    width: 100% !important;
  } 
  .code_verification .cat.action {
    margin: 10px 0px;
    width: 100%;
  }

  #existing_num p,
  #new_num p {
    font-size: 16px;
  }

  .generic-help-link {
    bottom: 85px;
  }

  .generic-help-link {
    position: static !important;
  }

  .changePlan {
    top         : -35px;
    width       : 55%;
    left        : 0;
    right       : 0;
    margin-left : auto;
    margin-right: auto;
  }

  .spacer-addon-banner {
    height: 60px;
  }

  .random_number {
    font-size: 14px !important;
  }

  .sidebar-toggle {
    /* top       : 80%; */
    z-index   : 9999;
    translate : unset;
    transition: unset;
    width: 25px;
    height: 60px;
  }

  /* .sidebar {
    left       : 0 !important;
    margin-left: 0px !important;
    height     : 100vh;
    width      : 100vw;
    transition : .7s z-index;
  } */

  .main.slideRight {
    margin-left: unset !important;
  }

  .sidebar-toggle.active {
    z-index                   : 9999;
    background                : #1a75bb;
    color                     : #fff;
    right                     : 0px;
    left                      : unset;
    border-top-left-radius   : 100%;
    border-bottom-left-radius: 100%;
    border-top-right-radius   : 0%;
    border-bottom-right-radius: 0%;
  }
  .main .step .lg-heading{
    margin-bottom: 10px !important;
  }
  .radio-group .pic{
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .main .step .footer{
    margin-top: 20px;
  }
  .main .step .sm-heading{
    font-size: 13px;
  }
  .main .step{
    width: 400px !important;
  }
}