body {
  font-family: 'Open Sans', sans-serif !important;
}

.sidebar {
  background-color: #4674b8 !important;
}

.sidebar .sidebar-brand {
  height: 95px;
  padding: 15px 0 15px 20px;
  background: #fff;
}

.sidebar .sidebar-nav .nav-link {
  position: relative;
  padding: 19px 30px;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}

.sidebar-nav .nav-group-toggle::after {
  flex: 0 1 16px;
  height: 16px;
}

.sidebar-nav .nav-link::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 12px solid #3465ad;
  opacity: 0;
  transition: opacity 0.1s;
}

.sidebar-nav .nav-link.active {
  position: relative;
  color: #fff;
  background: #3465ad;
  border-top: 2px solid #2f5c9d;
}

.sidebar-nav .nav-link.active::before {
  opacity: 1;
  transition: opacity 0.1s;
}

.sidebar-nav .nav-link:hover {
  background: #3465ad;
}

.sidebar-nav .place-order-container {
  padding: 33px 20px 40px;
}

.sidebar-nav .place-order-btn {
  display: flex;
  align-items: center;
  padding: 10px 30px 10px 15px;
  background-color: rgb(255, 69, 0);
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
  border-radius: 30px;
  box-shadow: 0px 10px 16px 0px rgba(18, 42, 78, 0.32);
  transition: all 0.2s;
}

.sidebar-nav .place-order-btn:hover {
  color: #ff4500;
  background-color: #fff;
  text-decoration: none;
  transition: all 0.2s;
}

.sidebar-nav .place-order-btn img {
  margin-right: 10px;
}

.sidebar-nav .place-order-btn .icon-orange {
  display: none;
}

.sidebar-nav .place-order-btn:hover .icon-white {
  display: none;
}

.sidebar-nav .place-order-btn:hover .icon-orange {
  display: block;
}

select.profile-field {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

.main-wrapper {
  background-color: #f6f6f4;
}

/*Main Header Bar*/
.header {
  min-height: 95px;
}

.header .cp-heading {
  font-size: 24px;
  font-weight: 300;
  color: #737373;
  text-transform: capitalize;
  line-height: 1;
  margin-left: 20px;
}

.header .profile-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  width: 210px;
  min-height: 54px;
  border: 2px solid rgb(77, 121, 187);
  line-height: 1;
  padding: 0 0 0 65px;
  border-radius: 30px;
  background: url("../images/icons/icon-profile-dd.png") no-repeat;
  background-position: center right 15px;
}

.header .profile-dropdown .name-letters {
  position: absolute;
  left: -1px;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;
  width: 53px;
  min-width: 50px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
  background-color: #4d79bb;
  border-radius: 50%;
}

.header .profile-dropdown .details .welcome-text {
  font-size: 14px;
  font-weight: 500;
  color: #737373;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.header .profile-dropdown .details .name-text {
  font-size: 18px;
  font-weight: 400;
  color: #737373;
  line-height: 1;
  text-transform: capitalize;
}

.header .profile-dropdown+.dropdown-menu.show {
  width: 100%;
}

.header .logout-btn {
  display: inline-flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  font-size: 17px;
  font-weight: 500;
  color: #4d79bb;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  padding: 16px 15px;
  border: 2px solid #4d79bb;
  border-radius: 30px;
  margin-left: 20px;
  transition: all 0.2s;
}

.header .logout-btn:hover {
  color: #fff;
  background-color: #4d79bb;
  text-decoration: none;
  transition: all 0.2s;
}

.header .profile-dropdown+.dropdown-menu .dropdown-item:active {
  background-color: #4674b8 !important;
}

.header .logout-btn img {
  margin-right: 7px;
}

.header .logout-btn .icon-white {
  display: none;
}

.header .logout-btn:hover .icon-blue {
  display: none;
}

.header .logout-btn:hover .icon-white {
  display: block;
}

/*Start of Top Widgets*/
.top-widgets {
  border-radius: 15px;
  margin: 0;
}

.top-widgets .widget {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 12px 23px 0px rgba(18, 42, 78, 0.1);
}

.top-widgets .widget1 {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.top-widgets .widget3 {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

/*Widget 1*/
.top-widgets .widget .amount {
  position: absolute;
  right: 20px;
  top: 23px;
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
}

.top-widgets .widget1 .current-plan {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.top-widgets .widget1 .current-plan .plan-icon {
  min-width: 30px;
  width: 30px;
  margin-right: 12px;
}

.top-widgets .widget1 .current-plan .heading-sm {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.top-widgets .widget1 .current-plan .heading-lg {
  font-size: 22px;
  font-weight: 300;
  color: #ff4500;
  line-height: 1;
  text-transform: uppercase;
}

.top-widgets .widget1 .action-btns {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.top-widgets .widget1 .action-btns .view-btn {
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  color: #737373;
  line-height: 1;
  text-align: center;
  background-color: transparent;
  padding: 7px 13px;
  text-transform: capitalize;
  text-decoration: none;
  border: 2px solid #4d79bb;
  border-radius: 15px;
  margin-right: 7px;
  transition: all 0.2s;
}

.top-widgets .widget1 .action-btns .view-btn:hover {
  color: #fff;
  background-color: #4d79bb;
  transition: all 0.2s;
}

.top-widgets .widget1 .action-btns .upgrade-btn {
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  text-align: center;
  background-color: #ff4500;
  padding: 7px 10px;
  text-transform: capitalize;
  text-decoration: none;
  border: 2px solid #ff4500;
  border-radius: 15px;
  margin-left: 7px;
  transition: all 0.2s;
}

.top-widgets .widget1 .action-btns .upgrade-btn:hover {
  color: #ff4500;
  background-color: #fff;
  transition: all 0.2s;
}

/*Widget 2*/
.top-widgets .widget2 .account-completion {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.top-widgets .widget2 .account-completion .completion-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 55px;
  width: 55px;
  height: 55px;
  margin-right: 12px;
}

.top-widgets .widget2 .account-completion .completion-progress img {
  max-width: 100%;
}

.top-widgets .widget2 .account-completion .heading {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.top-widgets .widget2 .account-completion .desc {
  font-size: 12px;
  font-weight: 400;
  color: #555555;
  line-height: 1.3;
}

.top-widgets .widget2 .method-btn {
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  text-align: center;
  background-color: #4674b8;
  padding: 7px 13px;
  text-transform: capitalize;
  text-decoration: none;
  border: 2px solid #4674b8;
  border-radius: 15px;
  margin-top: auto;
  transition: all 0.2s;
}

.top-widgets .widget2 .method-btn:hover {
  color: #4674b8;
  background-color: #fff;
  transition: all 0.2s;
}

/*Widget 3*/
.top-widgets .widget3 .heading {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.top-widgets .widget3 .visa-card {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.top-widgets .widget3 .visa-card .visa-card-img {
  min-width: 55px;
  width: 55px;
  margin-right: 10px;
}

.top-widgets .widget3 .visa-card .visa-card-img img {
  max-width: 100%;
}

.top-widgets .widget3 .visa-card .visa-card-number {
  font-size: 12px;
  font-weight: 400;
  color: #555555;
  line-height: 1;
}

.top-widgets .widget3 .details-btn {
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  text-align: center;
  background-color: #4674b8;
  padding: 7px 13px;
  text-transform: capitalize;
  text-decoration: none;
  border: 2px solid #4674b8;
  border-radius: 15px;
  margin-top: auto;
  transition: all 0.2s;
}

.top-widgets .widget3 .details-btn:hover {
  color: #4674b8;
  background-color: #fff;
  transition: all 0.2s;
}

/*End of Top Widgets*/
/*Start of Custom Accordion Card*/
.custom-accordion {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 12px 23px 0px rgba(18, 42, 78, 0.1);
  border: 0 !important;
  border-radius: 15px;
}

.custom-accordion .accordion-item {
  border-radius: 15px !important;
}

.custom-accordion .accordion-header .accordion-button {
  font-size: 18px;
  font-weight: 700;
  color: #555555;
  text-transform: uppercase;
  padding: 20px 30px;
  border-radius: 15px !important;
}

.custom-accordion .accordion-header .accordion-button:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
}

.custom-accordion .accordion-header .accordion-button:not(.collapsed) {
  background-color: #fff;
  box-shadow: none;
}

.custom-accordion .accordion-body {
  padding: 10px 30px 30px;
}

/*End of Custom Accordion Card*/
/*Start of Call History Table*/
.call-history-table>tbody>tr:nth-of-type(odd)>td {
  background-color: #f6f6f4 !important;
}

.call-history-table tr th:last-child,
.call-history-table tr td:last-child {
  text-align: right;
}

.call-history-table thead tr th {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 8px 20px;
  border: 0;
}

.call-history-table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 13px 20px;
  vertical-align: middle;
  border: 0;
}

.call-history-table tr td .view-details-btn {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #737373;
  line-height: 1;
  text-align: center;
  background-color: transparent;
  padding: 6px 20px;
  text-transform: capitalize;
  text-decoration: none;
  border: 2px solid #4d79bb;
  border-radius: 15px;
  transition: all 0.2s;
}

.call-history-table tr td .view-details-btn:hover {
  color: #fff;
  background-color: #4d79bb;
  transition: all 0.2s;
}

/*End of Call History Table*/
/*Start of My Payments Table*/
.payment-box {
  padding: 0 18px;
}

.payment-box.border-r-l {
  border-left: 2px solid #e5e5e5;
  border-right: 2px solid #e5e5e5;
}

.payment-box .payment-heading {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.payments-table {
  width: 100%;
  margin-bottom: 10px;
}

.payments-table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 10px 5px;
  vertical-align: middle;
  border-bottom: 2px solid #e5e5e5;
}

.payments-table tr td:first-child {
  width: 30%;
  font-weight: 700;
}

.payments-table tr td:last-child {
  text-align: right;
}

.payments-table tbody tr:last-child td {
  border-bottom: 0;
  padding-right: 0;
}

.payment-box .view-all-btn {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #4674b8;
  line-height: 1;
  text-transform: capitalize;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 2px solid #4674b8;
}

/*End of My Payments Table*/
/*Start of Activity Timline*/
.activity-card {
  height: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 12px 23px 0px rgba(18, 42, 78, 0.1);
  border-radius: 15px;
}

.activity-card .activity-card-header {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: uppercase;
  padding: 25px 20px 10px;
}

.activity-card .activity-card-body {
  padding: 10px 20px 20px;
}

.activity-card .activity-card-body .activity-timeline {
  list-style-type: none;
  position: relative;
  padding: 0;
  margin-bottom: 0;
}

.activity-card .activity-card-body .activity-timeline:before {
  content: "";
  background: #e8e4e4;
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 4px;
  height: 100%;
  z-index: 400;
}

.activity-card .activity-card-body .activity-timeline li {
  margin-bottom: 35px;
  padding-left: 32px;
}

.activity-card .activity-card-body .activity-timeline li:last-child {
  margin-bottom: 0;
}

.activity-card .activity-card-body .activity-timeline li:before {
  content: "";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 4px solid;
  left: 0;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.activity-card .activity-card-body .activity-timeline li:nth-child(3n+1):before {
  border: 4px solid #4d79bb;
}

.activity-card .activity-card-body .activity-timeline li:nth-child(3n+2):before {
  border: 4px solid #a4cd3c;
}

.activity-card .activity-card-body .activity-timeline li:nth-child(3n):before {
  border: 4px solid #e2bf32;
}

.activity-card .activity-card-body .activity-timeline li .date {
  font-size: 12px;
  font-weight: 400;
  color: #555555;
  line-height: 1;
  margin-bottom: 5px;
}

.activity-card .activity-card-body .activity-timeline li .desc {
  font-size: 12px;
  font-weight: 400;
  color: #555555;
  line-height: 1.3;
  text-transform: capitalize;
  margin-bottom: 0;
}

/*End of Activity Timline*/
/*@media (min-width: 992px){
  .sidebar.sidebar-fixed:not(.sidebar-end) {
    left: 0;
  }
}
@media (max-width: 991px){
  .sidebar:not(.sidebar-end):not(.show) {
    margin-left: calc(-1 * var(--cui-sidebar-width));
  }
  .sidebar:not(.sidebar-end) ~ *{
    padding-left: 0 !important;
  }
  .sidebar:not(.sidebar-end) {
    margin-left: 0 !important;
  }
}*/
@media only screen and (min-width: 1350px) and (max-width: 1500px) {
  .top-widgets .widget {
    zoom: 0.9;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1349.98px) {
  .top-widgets .widget {
    zoom: 0.8;
  }

  .custom-accordion {
    zoom: 0.9;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {

  .top-widgets .widget,
  .custom-accordion {
    zoom: 0.8;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .header .profile-dropdown {
    width: 200px;
    min-height: 47px;
    padding: 0px 0 0 60px;
  }

  .header .profile-dropdown .name-letters {
    height: 45px;
    width: 45px;
    min-width: 45px;
    font-size: 16px;
  }

  .top-widgets .widget {
    border-radius: 10px;
  }

  .top-widgets .widget2 .account-completion,
  .top-widgets .widget3 .visa-card {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 991.98px) {

  /* .sidebar,
  .sidebar:not(.sidebar-end):not(.show) {
    width: 0;
    margin-left: 0 !important;
  } */
  .main-wrapper {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 767.98px) {
  .top-widgets .widget {
    border-radius: 10px;
  }

  .top-widgets .widget2 .account-completion,
  .top-widgets .widget3 .visa-card {
    margin-bottom: 20px;
  }

  .custom-accordion {
    border-radius: 10px;
  }

  .custom-accordion .accordion-item {
    border-radius: 10px !important;
  }

  .custom-accordion .accordion-header .accordion-button {
    font-size: 16px;
    padding: 15px;
    border-radius: 10px !important;
  }

  .custom-accordion .accordion-body {
    padding: 10px 15px 20px;
  }

  .payment-box {
    padding: 0;
  }

  .payment-box.border-r-l {
    border-left: 0;
    border-right: 0;
  }

  .activity-card {
    border-radius: 10px;
  }

  .activity-card .activity-card-header {
    font-size: 16px;
    padding: 25px 20px 20px;
  }

  .activity-card .activity-card-body {
    padding: 10px 20px 30px;
  }

  .activity-card .activity-card-body .activity-timeline li .date,
  .activity-card .activity-card-body .activity-timeline li .desc {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767.98px) {
  .header .header-brand img {
    width: 180px;
  }

  .header .custom-dd {
    width: 100%;
    margin: 10px 0;
  }

  .header .custom-dd li {
    width: 100%;
  }

  .header .custom-dd .logout-btn {
    width: auto;
    font-size: 15px;
    padding: 13px 20px;
    border-radius: 5px;
    margin-left: 15px;
  }

  .header .profile-dropdown {
    width: 100%;
    min-height: 49px;
    padding: 0 0 0 65px;
    border-radius: 5px;
  }

  .header .profile-dropdown .name-letters {
    height: 48px;
    width: 48px;
    min-width: 48px;
    font-size: 16px;
    border-radius: 5px;
  }
}

.unverified-user {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height      : calc(100vh - 120px); */
  padding: 30px;
}

/* Start Services and Orders Serch Filters */
.search-filter,
.per-page {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.search-filter .filter-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.search-filter .filter-item label {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: capitalize;
  margin: 0 10px 0 0;
}

.search-filter .filter-number select,
.search-filter .filter-service select,
.per-page select {
  background-color: #f6f6f4;
  padding: 7px 30px 7px 10px;
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  text-transform: capitalize;
  border-radius: 25px;
  border: 0;
  outline: 0;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../images/icons/filter-arrow.png');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 10px;
}

.search-filter .filter-date {
  margin-right: 0;
}

.search-filter .filter-date .date-from,
.search-filter .filter-date .date-to {
  position: relative;
  width: 120px;
  background-color: #f6f6f4;
  padding: 7px 15px 7px 30px;
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  border-radius: 25px;
  border: 0;
  outline: 0;
}

.search-filter .filter-date .date-from {
  margin-right: 10px;
}

.search-filter .filter-date input[type="date"]::-webkit-inner-spin-button,
.search-filter .filter-date input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 10px;
  top: 10px;
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('../images/icons/filter-calendar.png') no-repeat;
}

.search-filter .search-bar .transactio-search,
.search-filter .search-bar .search-only {
  height: 31px;
  background-color: #fff;
  font-size: 11px;
  font-weight: 700;
  color: #555555;
  text-transform: uppercase;
  border-radius: 25px;
  border: 2px solid #ccc;
  outline: 0;
}

.search-filter .search-bar .transactio-search {
  max-width: 170px;
  padding: 3px 10px;
  margin-right: 10px;
}

.search-filter .search-bar .search-only {
  max-width: 145px;
  padding: 3px 10px 3px 35px;
  background: url('../images/icons/icon-search.png') no-repeat;
  background-position: left 10px center;
}

.per-page .filter-item {
  display: flex;
  align-items: center;
}

.per-page .filter-item label {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: capitalize;
  margin: 0 0 0 10px;
}

@media screen and (min-width: 1367px) {

  .service-card .service-card-header,
  .my-orders-card .my-orders-card-header,
  .payments-header {
    display: flex !important;
  }
}

@media screen and (max-width: 1366px) {
  .search-filter {
    justify-content: flex-start;
    margin-top: 25px
  }

  .search-filter .filter-item label {
    display: block !important;
    margin: 0 0 7px 10px;
  }
}

@media screen and (max-width: 1366px) and (min-width: 768px) {
  .search-filter .filter-item {
    display: block;
  }
}

@media screen and (max-width: 767.98px) {
  .global-card {
    padding: 20px 15px 15px 15px !important;
  }

  .search-filter {
    display: block;
  }

  .filter-number {
    margin-right: 8px !important;
    display: inline-block !important;
    width: calc(50% - 8px);
  }

  .filter-service {
    margin-right: 0 !important;
    margin-left: 8px;
    display: inline-block !important;
    width: calc(50% - 8px);
  }

  .search-filter .filter-number select,
  .search-filter .filter-service select {
    width: 100%;
  }

  .search-filter .filter-date {
    display: block;
    margin-top: 15px;
  }

  .search-filter .filter-date .date-from {
    width: calc(50% - 8px);
    margin-right: 8px !important;
  }

  .search-filter .filter-date .date-to {
    width: calc(50% - 0px);
    margin-right: 0 !important;
    margin-left: 0px;
  }

  .search-filter .search-bar {
    margin-top: 15px;
    margin-right: 0;
  }

  .search-filter .search-bar .transactio-search,
  .search-filter .search-bar .search-only {
    width: 50%;
    max-width: 50%;
  }

  .per-page {
    margin-top: 15px;
  }
}

/* End Services and Orders Serch Filters */
/* Start Services Page */
.global-card {
  padding: 20px 30px 30px 30px;
  background-color: #fff;
  box-shadow: 0px 12px 23px 0px rgba(18, 42, 78, 0.1);
  border-radius: 15px;
}

.service-card .service-card-header {
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.service-card .service-card-header .heading {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: uppercase;
}

.service-card .service-card-header .heading .header-icon {
  min-width: 15px;
  margin-right: 10px;
}

/*Start of Service/Activity log Table*/
.services-table>tbody>tr:nth-of-type(odd)>td {
  background-color: #f6f6f4 !important;
}

.services-table tr th:last-child,
.services-table tr td:last-child {
  text-align: right;
}

.services-table thead tr th {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 8px 20px;
  border: 0;
}

.services-table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 14px 20px;
  vertical-align: middle;
  border: 0;
}

.services-table tr td .col-caller-container,
.services-table tr td .col-service-container,
.services-table tr td .col-status-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.services-table tr td .col-caller-container img {
  min-width: 18px;
  max-width: 18px;
  margin-right: 10px;
}

.services-table tr td .col-service-container img {
  min-width: 18px;
  max-width: 18px;
  margin-right: 8px;
}

.services-table tr td .col-status-container .status-bullet {
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  background-color: #f1b924;
  margin-right: 8px;
}

.services-table tr td .col-status-container .status-text {
  font-weight: 700;
  color: #f1b924;
  text-transform: uppercase;
}

.services-table tr td .col-actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.services-table tr td .action-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-decoration: none;
  border-radius: 50%;
  transition: all 0.2s;
}

.services-table tr td .play-btn {
  background-color: #4d79bb;
  border: 2px solid #4d79bb;
  font-size: 13px;
}

.services-table tr td .eye-btn {
  background-color: #4d79bb;
  border: 2px solid #4d79bb;
  font-size: 15px;
}

.services-table tr td .play-btn:hover,
.services-table tr td .eye-btn:hover {
  color: #4d79bb;
  background-color: #fff;
  transition: all 0.2s;
}

.services-table tr td .delete-btn {
  background-color: #ce2539;
  border: 2px solid #ce2539;
  font-size: 25px;
  margin-left: 7px;
}

.services-table tr td .delete-btn:hover {
  color: #ce2539;
  background-color: #fff;
  transition: all 0.2s;
}

/* End of Service/Activity log Table */
/* Start Custom Modals */
.customModal .modal-header .modal-title {
  line-height: 1;
  font-size: 20px;
  color: #555;
  font-weight: 700;
}

.voiceModal audio {
  display: block;
  width: 100%;
}

.customModal .modal-footer .close-btn {
  color: white;
  background-color: #ff4500;
  outline: 0;
  border: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.customModal .modal-footer .close-btn:focus,
.customModal .modal-footer .yes-btn:focus {
  box-shadow: none;
}

.deleteModal .modal-footer .yes-btn {
  color: white;
  background-color: #4d79bb;
  outline: 0;
  border: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.addNewCardModal .modal-body {
  padding: 1rem 1.5rem;
}

.addNewCardModal .modal-body .payment-icons {
  margin: 5px 0 25px 0;
}

.addNewCardModal .modal-body .payment-icons img {
  width: 50px;
  margin: 0 3px;
  display: block;
  border-radius: 3px;
}

.addNewCardModal .modal-body form label {
  font-size: 14px;
  font-weight: 600;
  color: #555555;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 8px;
}

.addNewCardModal .modal-body form .form-control {
  font-size: 15px;
  color: #555555;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: 0;
  box-shadow: none;
}

.addNewCardModal .modal-body form select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../images/icons/filter-arrow.png');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 10px;
}

.modal-backdrop {
  display: block !important;
}

/* End Custom Modals */
/* End Services Page */
/* Start My Orders Page */
.my-orders-card .my-orders-card-header {
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.my-orders-card .my-orders-card-header .heading {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: uppercase;
}

.my-orders-card .my-orders-card-header .heading .header-icon {
  min-width: 15px;
  margin-right: 10px;
}

/* Start My Orders Table */
.my-orders-table>tbody>tr:nth-of-type(odd)>td {
  background-color: #f6f6f4 !important;
}

.my-orders-table>thead>tr>th:last-child,
.my-orders-table>tbody>tr>td:last-child {
  text-align: left;
}

.my-orders-table>thead>tr>th {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 8px 20px;
  border: 0;
}

.my-orders-table>tbody>tr>td {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 14px 20px;
  vertical-align: top;
  border: 0;
}

.my-orders-table>tbody>tr>td .col-caller-container,
.my-orders-table>tbody>tr>td .status-canceled,
.my-orders-table>tbody>tr>td .status-completed,
.my-orders-table>tbody>tr>td .status-on-hold {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.my-orders-table>tbody>tr>td .col-caller-container img {
  min-width: 18px;
  max-width: 18px;
  margin-right: 10px;
}

.my-orders-table>tbody>tr>td .status-canceled .status-bullet,
.my-orders-table>tbody>tr>td .status-completed .status-bullet,
.my-orders-table>tbody>tr>td .status-on-hold .status-bullet {
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  margin-right: 8px;
}

.my-orders-table>tbody>tr>td .status-canceled .status-bullet {
  background-color: #ed8a8a;
}

.my-orders-table>tbody>tr>td .status-completed .status-bullet {
  background-color: #88dd9c;
}

.my-orders-table>tbody>tr>td .status-on-hold .status-bullet {
  background-color: #d56be5;
}

.my-orders-table>tbody>tr>td .status-canceled .status-text,
.my-orders-table>tbody>tr>td .status-completed .status-text,
.my-orders-table>tbody>tr>td .status-on-hold .status-text {
  font-weight: 700;
  text-transform: uppercase;
}

.my-orders-table>tbody>tr>td .status-canceled .status-text {
  color: #ed8a8a;
}

.my-orders-table>tbody>tr>td .status-completed .status-text {
  color: #88dd9c;
}

.my-orders-table>tbody>tr>td .status-on-hold .status-text {
  color: #d56be5;
}

.my-orders-table>tbody>tr>td .details-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  background-color: #d6d9de;
  font-size: 13px;
  font-weight: 700;
  color: #383838;
  line-height: 1;
  border: 2px solid #d6d9de;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 25px;
  transition: all 0.2s;
  margin-right: 5px;
}

.my-orders-table>tbody>tr>td .details-btn:hover {
  background-color: #fff;
  border: 2px solid #d6d9de;
  transition: all 0.2s;
}

.sub-table>tr>td {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  padding: 8px 20px;
  vertical-align: middle;
  border-bottom: 1px solid #ddddda;
}

.sub-table>tr:last-child>td {
  border-bottom: 0 !important;
}

/* End My Orders Table */
/* Start Orders Details Table */
.order-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.order-details-header .detail-breadcrums {
  display: flex;
  align-items: center;
}

.order-details-header .detail-breadcrums span {
  font-size: 18px;
}

.order-details-header .detail-breadcrums .link {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #737373;
  text-decoration: none;
  border-bottom: 2px solid #b3b3b3;
  line-height: 1.3;
  cursor: auto;
  margin-left: 5px;
}

.order-details-header .detail-breadcrums .link.clickable {
  cursor: pointer !important;
  margin-right: 5px;
  margin-left: 0 !important;
}

.order-details-header .action-btns {
  display: flex;
  align-items: center;
}

.order-details-header .action-btns .btn-back {
  background-color: #4d79bb;
  padding: 7px 12px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  outline: 0;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
}

.order-details-header .action-btns .btn-print {
  background-color: #ff4500;
  padding: 7px 12px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  outline: 0;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
  margin-left: 10px;
}

.order-details-header .action-btns .btn-back i,
.order-details-header .action-btns .btn-print i {
  margin-right: 2px;
}

.details-card {
  padding: 20px 20px 10px;
  background-color: #f6f6f4;
  border-radius: 10px;
  height: 100%;
}

.details-card .heading {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.details-card .heading i {
  font-size: 20px;
  margin-right: 10px;
}

.order-details-table> :not(:first-child) {
  border-top: 0 !important;
}

.order-details-table>tr>th,
.order-details-table>tr>td {
  font-size: 14px;
  color: #555555;
  text-transform: capitalize;
  white-space: nowrap;
  background-color: #f6f6f4 !important;
  padding: 14px 12px;
  vertical-align: middle;
  border: 0;
  border-top: 1px solid #cecece !important;
}

.order-details-table>tr>th {
  font-weight: 700;
  width: 40%;
}

.order-details-table>tr>td {
  font-weight: 400;
  width: 60%;
}

/* End Orders Details Table */
/* End My Orders Page */
/* Start Custom Pagination Css */
.custom-pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-pagination-container .pagin-item {
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  text-decoration: none;
  background-color: #f6f6f4;
  border-radius: 50%;
  margin: 20px 10px 20px 0;
}

.custom-pagination-container .pagin-item:last-child {
  margin-right: 0;
}

.custom-pagination-container .pagin-item a {
  display: block;
  width: 100%;
  text-align: center;
}

.custom-pagination-container .btn-prev,
.custom-pagination-container .btn-next {
  font-size: 18px;
}

.custom-pagination-container .btn-prev>a,
.custom-pagination-container .btn-next>a {
  text-decoration: none;
  color: #555
}

.custom-pagination-container .btn-number.pagin-active {
  border: 2px solid rgb(77, 121, 187);
}

/* End Custom Pagination Css */
/* Start Payment Info Css */
.payment-info {
  padding: 30px 40px;
  background-color: #fff;
}

.payment-info .payment-info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payment-info .payment-info-header .main-heading {
  font-size: 14px;
  font-weight: 700;
  color: #555;
  line-height: 1;
  text-transform: uppercase;
}

.payment-info .payment-info-header .btn-add-card {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #ff4500;
  line-height: 1;
  text-transform: uppercase;
  padding: 11px 15px 9px;
  border: 2px solid #ff4500;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0px 12px 23px 0px rgba(0, 0, 0, 0.07);
}

.payment-info .payment-info-header .btn-add-card img {
  width: 20px;
  margin-right: 5px;
}

.payment-info .payment-info-body .payment-item {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 35px 30px 35px 30px;
  border: 1px solid rgb(226, 234, 233);
  border-radius: 10px;
  background-color: rgb(248, 248, 248);
  box-shadow: 0px 12px 23px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
}

.payment-info .payment-info-body .payment-item:last-child {
  margin-bottom: 0;
}

.payment-info .payment-info-body .payment-item.active {
  border: 1px solid rgb(50, 190, 166) !important;
}

.payment-item .payment-card-img {
  min-width: 130px;
  margin-right: 30px;
  box-shadow: 0px 12px 23px 0px rgba(0, 0, 0, 0.07);
}

.payment-item .payment-card-img img {
  max-width: 130px;
  height: auto;
}

.payment-details .card-name {
  font-size: 30px;
  font-weight: 400;
  color: #555;
  line-height: 1;
  text-transform: uppercase;
  margin: 10px 0 18px;
}

.payment-details .card-number {
  font-size: 18px;
  font-weight: 700;
  color: #555;
  line-height: 1;
  margin-bottom: 22px;
}

.payment-details .address {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  line-height: 1;
  margin-bottom: 20px;
}

.payment-details .address img {
  width: 18px;
  margin-right: 12px;
}

.payment-details .address strong {
  margin-right: 20px;
}

.payment-details .date {
  display: flex;
  align-items: center;
}

.payment-details .date .web-icon {
  width: 18px;
  margin-right: 12px;
}

.payment-details .date strong {
  margin-right: 20px;
}

.payment-details .date .creation,
.payment-details .date .updation,
.payment-details .date .expiry {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  line-height: 1;
  margin-right: 60px;
}

.payment-details .date .creation .mob-icon,
.payment-details .date .updation .mob-icon,
.payment-details .date .expiry .mob-icon {
  display: none;
}

.payment-details .date .expiry {
  margin-right: 0;
}

.payment-item .action-btns {
  position: absolute;
  right: 35px;
  top: 30px;
  display: flex;
  align-items: center;
}

.payment-item .action-btns .delete-btn,
.payment-item .action-btns .edit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  border-radius: 50%;
  border: 0;
  outline: 0;
}

.payment-item .action-btns .delete-btn {
  background-color: #ce2539;
  font-size: 30px;
  font-weight: 500;
  margin-right: 10px;
}

.payment-item .action-btns .delete-btn[disabled] {
  background-color: #d4cccd !important;
  color: #b1a5a7 !important;
}

.payment-item .action-btns .edit-btn {
  background-color: #4d79bb;
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
}

.payment-item .action-btns .set-default-btn,
.payment-item .action-btns .default-btn {
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  border-radius: 30px;
  border: 0;
  outline: 0;
}

.payment-item .action-btns .set-default-btn {
  background-color: #4d79bb;
  padding: 0 15px;
}

.payment-item .action-btns .default-btn {
  background-color: #32bea6;
  padding: 0 40px 0 20px;
  position: relative;
}

.payment-item .action-btns .default-btn i {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 20px;
}

/* End Payment Info Css */
/* Start Custom Tabs */
.custom-tabs-card {
  background-color: #fff;
  box-shadow: 0px 12px 23px 0px rgb(18 42 78 / 10%);
  border-radius: 15px;
}

.custom-tabs .tab {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 700;
  color: #959595;
  text-transform: uppercase;
  background-color: rgb(246, 246, 244);
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-color: transparent !important;
  box-shadow: inset 0px 6px 12px 0px rgba(0, 0, 0, 0.07), inset 0px -2px 0px 0px rgba(223, 223, 223, 0.26);
  border-radius: 0;
}

.custom-tabs .tab:first-child {
  border-top-left-radius: 10px;
}

.custom-tabs .tab:last-child {
  border-top-right-radius: 10px;
}

.custom-tabs .tab i {
  font-size: 20px;
  margin-right: 8px;
}

.custom-tabs .tab.active {
  color: #555555;
  background-color: #fff;
  box-shadow: none;
}

.custom-tabs .tab.active i {
  color: #2588d9;
}

/* Start Profile tabs Content */
.profile-info {
  display: flex;
  align-items: flex-start;
  padding: 35px 45px 30px 45px;
  background-color: #fff;
  border-radius: 15px;
}

.profile-info .profile-box {
  margin-right: 50px;
}

.profile-info .profile-box .name {
  text-align: center;
}

.profile-box .pic .profileBoxHoverEdit {
  text-align: center;
}

.profile-box .pic {
  height: 140px;
  width: 140px;
  line-height: 140px;
  border: 4px solid #ebf1f9;
  background-color: #ebf1f9;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-box .pic img {
  width: auto;
  height: 100%;
}

.profile-box .name {
  font-size: 14px;
  font-weight: 600;
  color: #555;
  text-transform: capitalize;
  line-height: 1;
  margin-top: 15px;
}

.profile-info-table,
.profile-info-table table {
  width: 100%;
}

.profile-info-table {
  width: 100%;
}

/*Start Profile Table*/
.profile-info-table table>tbody>tr:nth-of-type(odd)>th,
.profile-info-table table>tbody>tr:nth-of-type(odd)>td {
  background-color: #f6f6f4 !important;
}

.profile-info-table table tr td:last-child {
  text-align: right;
}

.profile-info-table table tr th {
  width: 25%;
  font-weight: 700 !important;
}

.profile-info-table table tr th,
.profile-info-table table tr td {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  text-transform: capitalize;
  padding: 12px 30px;
  vertical-align: middle;
  border: 0;
}

.profile-info-table table tr td .profile-field {
  width: 100%;
  padding: 5px 10px;
  background-color: transparent;
  margin: 0;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: none;
  text-overflow: ellipsis;
}

.profile-info-table table tr td .profile-field[disabled] {
  width: 100%;
  background-color: transparent;
  padding: 6px 10px;
  margin: 0;
  outline: 0;
  border: 0;
  box-shadow: none;
  text-overflow: ellipsis;
}

.profile-info-table table tr td .action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  color: #4d79bb;
  line-height: 1;
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  outline: 0;
  margin-left: auto;
}

.profile-info-table table tr td .save-btn {
  color: #32bea6 !important;
}

.profile-info-table table tr td .action-btn img {
  width: 12px;
  margin-left: 5px;
}

/* End of Profile Table */
/* End of Profile tabs Content */
/* Start Payments tabs Content */
.payments-container {
  padding: 30px 30px 0px 30px;
  background-color: #fff;
  border-radius: 15px;
}

.payments-header {
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.payments-header .heading {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #555555;
  line-height: 1;
  text-transform: uppercase;
}

.payments-header .heading .header-icon {
  min-width: 15px;
  margin-right: 10px;
}

/*Start Payments Table*/
.payments-main-table {
  width: 100%;
  margin-bottom: 0;
}

.payments-main-table>tbody>tr:nth-of-type(odd)>td {
  background-color: #f6f6f4 !important;
}

.payments-main-table>thead>tr>th {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  text-transform: uppercase;
  text-align: center;
  padding: 8px 10px;
  border: 0;
}

.payments-main-table>tbody>tr>td {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  text-transform: capitalize;
  white-space: nowrap;
  text-align: center;
  padding: 10px 10px;
  vertical-align: middle;
  border: 0;
}

.payments-main-table>tbody>tr>td .long-text {
  max-width: 350px;
  white-space: break-spaces;
}

.payments-main-table>tbody>tr>td .did-container,
.payments-main-table>tbody>tr>td .btns-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payments-main-table>tbody>tr>td .did-container img {
  min-width: 18px;
  max-width: 18px;
  margin-right: 10px;
}

.payments-main-table>tbody>tr>td .details-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  background-color: #6691d7;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  border: 2px solid #6691d7;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 25px;
  transition: all 0.2s;
}

.payments-main-table>tbody>tr>td .details-btn,
.payments-main-table>tbody>tr>td .invoice-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 5px 15px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 25px;
  transition: all 0.2s;
}

.payments-main-table>tbody>tr>td .details-btn {
  background-color: #6691d7;
  color: #fff;
  border: 2px solid #6691d7;
  margin-right: 5px;
}

.payments-main-table>tbody>tr>td .details-btn:hover {
  color: #6691d7;
  background-color: #fff;
  transition: all 0.2s;
}

.payments-main-table>tbody>tr>td .invoice-btn {
  background-color: #cbdefe;
  color: #2d4877;
  border: 2px solid #cbdefe;
}

.payments-main-table>tbody>tr>td .invoice-btn:hover {
  color: #2d4877;
  background-color: #fff;
  transition: all 0.2s;
}

.payments-main-table>tbody>tr>td .invoice-btn img {
  width: 10px;
  margin-right: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .payment-tabs .tab {
    padding: 15px 15px;
    font-size: 17px;
  }

  .payment-info {
    padding: 25px 20px;
  }

  .payment-info .payment-info-body .payment-item {
    display: block;
    padding: 25px 25px 30px 25px;
  }

  .payment-item .action-btns {
    right: 25px;
    top: 25px;
  }

  .payment-item .payment-card-img {
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

@media only screen and (min-width: 300px) and (max-width: 991.98px) {
  .custom-tabs .tab {
    width: 33%;
    justify-content: center;
    padding: 15px 10px;
    font-size: 16px;
  }

  .profile-info {
    display: block;
    padding: 25px 15px 25px 15px;
  }

  .profile-info .profile-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0;
    margin-bottom: 35px;
  }

  .profile-box .name {
    font-size: 18px;
  }

  .profile-info-table table tr th,
  .profile-info-table table tr td {
    padding: 10px 10px;
    white-space: nowrap;
  }

  .profile-info-table table tr th {
    width: auto;
  }

  .profile-info-table table tr td .profile-field {
    min-width: 200px;
  }

  .payment-info {
    padding: 25px 15px;
  }

  .payment-info .payment-info-body .payment-item {
    display: block;
    padding: 30px 25px 30px 25px;
  }

  .payment-item .payment-card-img {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .payment-details .address {
    margin-bottom: 15px;
  }

  .payment-details .date {
    display: block;
  }

  .payment-details .date .web-icon {
    display: none;
  }

  .payment-details .date .creation,
  .payment-details .date .updation,
  .payment-details .date .expiry {
    margin-bottom: 15px;
    margin-right: 0;
  }

  .payment-details .date .creation .mob-icon,
  .payment-details .date .updation .mob-icon,
  .payment-details .date .expiry .mob-icon {
    display: inline-block;
    width: 18px;
    margin-right: 12px;
  }

  .payment-details .date .expiry {
    margin-bottom: 0;
  }

  .payment-item .action-btns {
    position: absolute;
    right: 20px;
  }

  .payment-item .action-btns .delete-btn,
  .payment-item .action-btns .edit-btn {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .payment-item .action-btns .set-default-btn,
  .payment-item .action-btns .default-btn {
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    text-transform: capitalize;
  }

  .payment-item .action-btns .default-btn {
    padding: 0 35px 0 15px;
  }

  .payment-item .action-btns .default-btn i {
    position: absolute;
    right: 12px;
    top: 9px;
    font-size: 18px;
  }

  .payments-container {
    padding: 0 15px 0 15px;
  }

  .payments-header {
    display: block;
    margin-bottom: 20px;
  }

  .payments-main-table>thead>tr>th {
    text-align: left;
    white-space: nowrap;
  }

  .payments-main-table>tbody>tr>td {
    font-size: 14px;
    text-align: left;
  }
}

@media screen and (max-width: 599.98px) {
  .custom-tabs .tab {
    padding: 10px 8px !important;
    font-size: 14px !important;
    line-height: 1.3 !important;
  }

  .custom-tabs .tab i {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .payment-info .payment-info-header {
    display: block;
    margin-bottom: 20px;
  }

  .payment-info .payment-info-header .main-heading {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .payment-info .payment-info-header .btn-add-card {
    width: 100%;
    font-size: 14px;
    border-radius: 7px;
  }

  .payment-info .payment-info-body .payment-item {
    display: block;
    padding: 20px 15px 20px 15px;
  }

  .payment-item .action-btns {
    position: relative;
    right: auto;
    top: auto;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .payment-details .card-name {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .payment-details .card-number {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .payment-details .address span {
    display: block;
    margin-top: 10px;
  }
}

/* End of Payments Table */
/* End of Payments tabs Content */
.profileBoxHoverEdit p i:before {
  color: #fff !important;
}

.profileBoxHoverEdit {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  width: 140px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  color: #fff;
  font-weight: bold;
  transition: .3s opacity;
}

.pic:hover>.profileBoxHoverEdit {
  opacity: 1;
}

.gravatarChoiceBox.active,
.uploadChoiceBox.active {
  border: 1px solid rgb(255, 69, 0);
  background-color: rgba(255, 69, 0, 0.1);
}

.gravatarChoiceBox,
.uploadChoiceBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 10px;
  height: 220px;
  border-radius: 20px;
  border: 1px solid #333;
  background-color: #f6f6f4;
}

.uploadChoiceBox .uploadBoxCircle {
  border-radius: 100%;
  background-color: rgb(77, 121, 187);
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 8rem;
  height: 8rem;
  position: relative;
}

.gravatarChoiceBox .gravatarBoxCircle {
  position: relative;
  cursor: pointer;
}

.uploadChoiceBox .uploadBoxCircle i:before {
  color: #fff;
  font-size: 22px;
}

.randomGeneratorButton {
  position: absolute;
  bottom: -10px;
  right: -10px;
  background: rgb(255, 69, 0);
  color: #fff;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 100%;
  transition: .3s background, color;
}

.randomGeneratorButton:hover {
  background: #fff;
  color: rgb(255, 69, 0);
}

.randomGeneratorButton:hover i::before {
  color: rgb(255, 69, 0);
}

.randomGeneratorButton i::before {
  font-size: 16px !important;
}

.uploadBoxInnerCircle img {
  height: 100%;
}

.uploadBoxInnerCircle {
  border-radius: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.proomotionBannerBox .thumbnail_box img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.proomotionBannerBox .description_box {
  width: 100%;
  height: 100%;
  background: #4674b8;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.proomotionBannerBox .thumbnail_box {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.widget3 .promotion-btn {
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  text-align: center;
  background-color: #ff4500;
  padding: 7px 10px;
  text-transform: capitalize;
  text-decoration: none;
  border: 2px solid #ff4500;
  transition: all 0.2s;
}

.proomotionBannerBox {
  /* background: url("../images/promo banner 2.jpg"); */
  /* background: #4674b8; */
  width: 100%;
  height: 100%;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-position: center;
  cursor: pointer;
  transition: .3s all;
}

/* .proomotionBannerBox:hover h1{
  font-size: 42px;
}
.proomotionBannerBox:hover h2{
  font-size: 22px;
} */
/* .proomotionBannerBox h1{
  animation: MoveUpDown 4s linear infinite;
  font-size: 40px;
  text-shadow: 2px 5px 10px #000000;
  font-weight: bold;
  color: #ff4500;
  transition: .3s all
}
.proomotionBannerBox h2:first-child{
  line-height: 0px;
}
.proomotionBannerBox h2{
  font-size: 20px;
    text-shadow: 2px 5px 10px #000000;
  animation: MoveUpDown 4s linear infinite;
  color: #fff;
  transition: .3s all;
  line-height: 20px;
}
@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(15px);
  }
} */
.profile-info-table .react-tel-input .form-control {
  padding-left: 65px !important;
  height: 35px !important;
  width: 300px !important;
  font-size: 14px !important;

}

.profile-info-table .location-search-input {
  width: 100%;
  padding: 5px 10px;
  background-color: transparent;
  margin: 0;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: none;
  text-overflow: ellipsis;
}

.autocomplete-dropdown-container {
  width: 99.9%;
}
.plan_options {
  max-height: 400px;
  overflow-y: scroll;
}

.saved_payment_cards {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.saved_payment_cards {
  scrollbar-width: thin;
  scrollbar-color: #ff4500 #ff4500;
}

/* Chrome, Edge, and Safari */
.saved_payment_cards::-webkit-scrollbar {
  width: 6px;
}

.saved_payment_cards::-webkit-scrollbar-track {
  background: #fff;
}

.saved_payment_cards::-webkit-scrollbar-thumb {
  background-color: #ff4500;
  border-radius: 9px;
  border: 0px inset #ffffff;
}
.click_noti {
  text-transform: uppercase;
  text-decoration: none;
  color: #41a0ff;
  font-weight: 700;
  font-size: 15px;
}
.notifications_list {
  list-style-type: disclosure-closed;
  line-height: 2;
}