.country-card[data-v-672309a0] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    transition: transform 0.3s ease;
    background-color: #fff;
}
  
  /* .country-card:hover {
    transform: scale(1.05);
    cursor: pointer;
  } */
.country-name[data-v-672309a0] {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}