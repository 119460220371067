.step.choose-plan[data-v-a5ef29a4] {
    min-width: 1200px;
}
.selecotr-item[data-v-a5ef29a4]{
    position:relative;
    height:100%;
}
.selector-item_radio[data-v-a5ef29a4]{
    -webkit-appearance:none;
            appearance:none;
    display:none;
}
.selector-item_label[data-v-a5ef29a4] {
    position: relative;
    width: 100%;
    text-align: center;
    border-radius: 20px;
    font-weight: 600;
    transition-duration: .5s;
    transition-property: transform, color, box-shadow;
    transform: none;
    cursor: pointer;
    display: block;
    border: 4px solid #d3d3d3;
    box-sizing: border-box;
}
.selector-item_label .selected-check[data-v-a5ef29a4] {
    position: absolute;
    top: -16px;
    right: -15px;
    height: 46px;
    background: rgb(255 69 0);
    background: linear-gradient(180deg, rgb(255 69 0) 0%, rgb(254 179 41) 100%);
    width: 45px;
    line-height: 45px;
    color: #fff;
    font-size: 28px;
    border-radius: 15px;
    transform: scale(0);
    transition: 0.5s;
}
.box-content[data-v-a5ef29a4] {
    background-color: #f0f6ff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.selector-item_radio:checked + .selector-item_label[data-v-a5ef29a4],
  .selector-item_label.checked[data-v-a5ef29a4] {
    background-color: var(--blue);
    color: var(--white);
    transform: translateY(-2px);
    border: 4px solid #ff4500;
}
.selector-item_radio:checked + .selector-item_label .selected-check[data-v-a5ef29a4],
  .selector-item_label.checked .selected-check[data-v-a5ef29a4]{
   transform: scale(1);
   transition: 0.5s;
}
.choose-plan .sm-heading[data-v-a5ef29a4] {
    font-size: 17px !important;
}
.selecotr-item h2[data-v-a5ef29a4] {
    background-color: #c4dcfd;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.row-col strong[data-v-a5ef29a4] {
    font-size: 17px;
}
.row-col[data-v-a5ef29a4] {
    border-bottom: 2px solid #c4dcfd;
}
.row-col span[data-v-a5ef29a4], .row-col strong[data-v-a5ef29a4] {
    line-height: 3;
}
.box-description strong[data-v-a5ef29a4] {
    display: block;
    width: 100%;
    text-align: left;
    line-height: 3;
    font-size: 17px;
}
.plan-row[data-v-a5ef29a4] {
    max-height: 610px;
    overflow: auto;
    padding-top: 20px;
}
.box-description .white-bg-text[data-v-a5ef29a4] {
    background-color: #fff;
    border-radius: 10px;
    line-height: 1.6;
    text-align: left;
    font-weight: 400;
    padding: 10px;
    font-size: 18px;
    height: 78px;
    overflow: auto;
}
.price-div .plan-price[data-v-a5ef29a4] {
    font-size: 30px;
    color: #1b75bc;
    font-weight: 600;
    display: block;
    margin-right: 8px;
}
.price-div .plan-duration[data-v-a5ef29a4] {
    color: #1b75bc;
    line-height: 1.8;
    font-weight: 600;
    font-size: 19px;
}
.row-col span[data-v-a5ef29a4] {
    font-weight: 400;
    font-size: 20px;
}
.plan-row .selecotr-item:nth-child(3n+2) h2[data-v-a5ef29a4] {
    background-color: #96f6f7;
}
.plan-row .selecotr-item:nth-child(3n+2) .box-content[data-v-a5ef29a4] {
    background-color: #f0ffff;
}
.plan-row .selecotr-item:nth-child(3n+2) .row-col[data-v-a5ef29a4] {
    border-bottom: 2px solid #96f6f7;
}
.plan-row .selecotr-item:nth-child(3n+3) h2[data-v-a5ef29a4] {
    background-color: #7cf3c8;
}
.plan-row .selecotr-item:nth-child(3n+3) .box-content[data-v-a5ef29a4] {
    background-color: #e9fff7;
}
.plan-row .selecotr-item:nth-child(3n+3) .row-col[data-v-a5ef29a4] {
    border-bottom: 2px solid #7cf3c8;
}
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
*[data-v-a5ef29a4] {
    scrollbar-width: thin;
    scrollbar-color: #ff4500 #ff4500;
}

  /* Chrome, Edge, and Safari */
*[data-v-a5ef29a4]::-webkit-scrollbar {
    width: 6px;
}
*[data-v-a5ef29a4]::-webkit-scrollbar-track {
    background: #fff;
}
*[data-v-a5ef29a4]::-webkit-scrollbar-thumb {
    background-color: #ff4500;
    border-radius: 9px;
    border: 0px inset #ffffff;
}
@media only screen and (max-width: 768px) {
.checkmark-wrapper[data-v-a5ef29a4] {
    display: none;
}
.step.choose-plan[data-v-a5ef29a4] {
    min-width: 100%;
}
}