/* From Uiverse.io by Yaya12085 */
.radio-inputs[data-v-19ad687c] {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
}
.radio-inputs > *[data-v-19ad687c] {
    margin: 6px;
}
.radio-input:checked + .radio-tile[data-v-19ad687c] {
    border-color: #2260ff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #2260ff;
}
.radio-input:checked + .radio-tile[data-v-19ad687c]:before {
    transform: scale(1);
    opacity: 1;
    background-color: #2260ff;
    border-color: #2260ff;
}
.radio-input:checked + .radio-tile .radio-icon svg[data-v-19ad687c] {
    fill: #2260ff;
}
.radio-input:checked + .radio-tile .radio-label[data-v-19ad687c] {
    color: #2260ff;
}
.radio-input:focus + .radio-tile[data-v-19ad687c] {
    border-color: #2260ff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}
.radio-input:focus + .radio-tile[data-v-19ad687c]:before {
    transform: scale(1);
    opacity: 1;
}
.radio-tile[data-v-19ad687c] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    min-height: 150px;
    border-radius: 0.5rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
}
.radio-tile[data-v-19ad687c]:before {
    content: "";
    position: absolute;
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
}
.radio-tile[data-v-19ad687c]:hover {
    border-color: #2260ff;
}
.radio-tile[data-v-19ad687c]:hover:before {
    transform: scale(1);
    opacity: 1;
}
.radio-icon svg[data-v-19ad687c] {
    width: 2rem;
    height: 2rem;
    fill: #494949;
}
.radio-label[data-v-19ad687c] {
    color: #707070;
    transition: 0.375s ease;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}
.radio-input[data-v-19ad687c] {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}